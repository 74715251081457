import { Logo } from "../../assets/brand";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { SelectorIcon } from "@heroicons/react/solid";
import { FC, Fragment } from "react";
import { Routes } from "../../routes";
import {
  AdjustmentsIcon,
  ChipIcon,
  ClipboardListIcon,
  CollectionIcon,
  GlobeAltIcon,
  HomeIcon,
  LockClosedIcon,
  ServerIcon,
  UserGroupIcon,
  XIcon,
} from "@heroicons/react/outline";
import { useHistory, useLocation, Link } from "react-router-dom";
import { useAuth, useProjects } from "../../hooks";

export const MenuHeader: FC = ({ children }) => (
  <h3 className="px-3 mb-2 text-xs font-semibold text-gray-500 uppercase tracking-wider">
    {children}
  </h3>
);

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

const renderMenu = (menu: any[], pathname?: string) => (
  <div className="space-y-1">
    {menu.map((item) => (
      <Link
        key={item.name}
        to={item.href}
        className={classNames(
          item.href === pathname
            ? "bg-gray-200 text-gray-900"
            : "text-gray-700 hover:text-gray-900 hover:bg-gray-50",
          "group flex items-center px-2 py-2 text-sm font-medium rounded-md"
        )}
        aria-current={item.current ? "page" : undefined}
      >
        <item.icon
          className={classNames(
            item.current
              ? "text-gray-500"
              : "text-gray-400 group-hover:text-gray-500",
            "mr-3 flex-shrink-0 h-6 w-6"
          )}
          aria-hidden="true"
        />
        {item.name}
      </Link>
    ))}
  </div>
);

const renderMenuItem = (title: string, link: string, cb?: () => void) => (
  <Menu.Item>
    {({ active }) =>
      link.includes("http") ? (
        <a
          target="_blank"
          href={link}
          className={classNames(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "block px-4 py-2 text-sm"
          )}
        >
          {title}
        </a>
      ) : (
        <Link
          to={link}
          onClick={(event) => {
            if (cb) {
              event.preventDefault();
              cb();
            }
          }}
          className={classNames(
            active ? "bg-gray-100 text-gray-900" : "text-gray-700",
            "block px-4 py-2 text-sm"
          )}
        >
          {title}
        </Link>
      )
    }
  </Menu.Item>
);

const generalNavigation = [
  {
    name: "Overview",
    href: Routes.private.project.home,
    icon: HomeIcon,
    current: false,
  },
];
const dataNavigation = [
  {
    name: "Data explorer",
    href: Routes.private.project.dataExplorer,
    icon: CollectionIcon,
    current: true,
  },
  {
    name: "Policies",
    href: Routes.private.project.policies,
    icon: LockClosedIcon,
    current: false,
  },
  {
    name: "Schema definition",
    href: Routes.private.project.schemaDefinition,
    icon: ClipboardListIcon,
    current: false,
  },
  {
    name: "Users",
    href: Routes.private.project.users,
    icon: UserGroupIcon,
    current: false,
  },
];
const systemNavigation = [
  {
    name: "Functions",
    href: Routes.private.project.functions,
    icon: ChipIcon,
    current: false,
  },
  {
    name: "DNS Management",
    href: Routes.private.project.dnsManagement,
    icon: ServerIcon,
    current: false,
  },
  {
    name: "Webhooks",
    href: Routes.private.project.webhooks,
    icon: GlobeAltIcon,
    current: false,
  },
  {
    name: "Settings",
    href: Routes.private.project.settings.general,
    icon: AdjustmentsIcon,
    current: false,
  },
];

type Props = {
  sidebarOpen: boolean;
  setSidebarOpen: (value: boolean) => void;
};
export const SideMenu: FC<Props> = ({ sidebarOpen, setSidebarOpen }) => {
  const { pathname } = useLocation();
  const { user } = useAuth();
  const history = useHistory();
  const { selectedProject } = useProjects();
  const { signOut } = useAuth();

  const logout = () => {
    signOut();
    history.replace(Routes.public.login);
  };

  return (
    <>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog
          as="div"
          className="fixed inset-0 flex z-40 lg:hidden"
          onClose={setSidebarOpen}
        >
          <Transition.Child
            as={Fragment}
            enter="transition-opacity ease-linear duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity ease-linear duration-300"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-600 bg-opacity-75" />
          </Transition.Child>
          <Transition.Child
            as={Fragment}
            enter="transition ease-in-out duration-300 transform"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition ease-in-out duration-300 transform"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
              <Transition.Child
                as={Fragment}
                enter="ease-in-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in-out duration-300"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="absolute top-0 right-0 -mr-12 pt-2">
                  <button
                    type="button"
                    className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                    onClick={() => setSidebarOpen(false)}
                  >
                    <span className="sr-only">Close sidebar</span>
                    <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                  </button>
                </div>
              </Transition.Child>
              <div className="flex-shrink-0 flex items-center px-4">
                <Logo width={40} height={40} />
              </div>
              <div className="mt-5 flex-1 h-0 overflow-y-auto">
                <nav className="px-2">
                  {renderMenu(generalNavigation)}
                  <div className="mt-8">
                    <MenuHeader>Data</MenuHeader>
                    {renderMenu(dataNavigation)}
                  </div>
                  <div className="mt-8">
                    <MenuHeader>System</MenuHeader>
                    {renderMenu(systemNavigation)}
                  </div>
                </nav>
              </div>
            </div>
          </Transition.Child>
          <div className="flex-shrink-0 w-14" aria-hidden="true">
            {/* Dummy element to force sidebar to shrink to fit close icon */}
          </div>
        </Dialog>
      </Transition.Root>
      <div className="hidden lg:flex lg:flex-col lg:w-64 lg:fixed lg:inset-y-0 lg:border-r lg:border-gray-200 lg:pt-5 lg:pb-4 lg:bg-gray-100">
        <div className="flex items-start flex-shrink-0 px-6">
          <Logo height={60} width={60} />
        </div>
        <div className="mt-6 h-0 flex-1 flex flex-col overflow-y-auto">
          <Menu as="div" className="mt-2 px-3 relative inline-block text-left">
            <div>
              <Menu.Button className="group w-full bg-gray-100 rounded-md pl-3.5 pr-1 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500">
                <span className="flex w-full justify-between items-center">
                  <span className="flex min-w-0 items-center justify-between space-x-3">
                    <span className="flex-1 flex flex-col min-w-0">
                      <span className="text-gray-900 text-sm font-medium truncate">
                        {selectedProject?.name}
                      </span>
                      <span className="text-gray-500 text-sm truncate">
                        Project ID: {selectedProject?.appId}
                      </span>
                    </span>
                  </span>
                  <SelectorIcon
                    className="flex-shrink-0 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                    aria-hidden="true"
                  />
                </span>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="z-10 mx-3 origin-top absolute right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
                <div className="py-1">
                  {renderMenuItem(
                    "Settings",
                    Routes.private.project.settings.general
                  )}
                </div>
                <div className="py-1">
                  <div className="px-4 py-2 text-sm text-gray-500">
                    External
                  </div>
                  {renderMenuItem("Download SDK", Routes.external.downloadSdks)}
                  {renderMenuItem(
                    "Explore Documentation",
                    Routes.external.documentation
                  )}
                </div>
              </Menu.Items>
            </Transition>
          </Menu>

          {/* Navigation */}
          <nav className="px-4 mt-6">
            {renderMenu(generalNavigation, pathname)}
            <div className="mt-10">
              <MenuHeader>Data</MenuHeader>
              {renderMenu(dataNavigation, pathname)}
            </div>

            <div className="mt-10">
              <MenuHeader>System</MenuHeader>
              {renderMenu(systemNavigation, pathname)}
            </div>
          </nav>
        </div>
        <Menu>
          <Menu.Button
            as="div"
            className="flex-shrink-0 flex border-t border-gray-200 p-4 cursor-pointer"
          >
            <div className="flex-shrink-0 w-full group block">
              <div className="flex items-center">
                <div>
                  <div className="inline-block h-10 w-10 rounded-full bg-green-500 flex items-center justify-center font-bold text-white">
                    <>
                      {user?.firstName ? user.firstName[0] : "?"}
                      {user?.lastName && user.lastName[0]}
                    </>
                  </div>
                </div>
                <div className="ml-3">
                  <p className="text-sm font-medium text-gray-700 group-hover:text-gray-900">
                    {user?.firstName
                      ? `${user?.firstName} ${user?.lastName}`
                      : "Your profile"}
                  </p>
                  <p className="text-xs font-medium text-gray-500 group-hover:text-gray-700">
                    System menu
                  </p>
                </div>
              </div>
            </div>
          </Menu.Button>

          <Menu.Items className="z-10 mx-3 origin-top absolute bottom-20 right-0 left-0 mt-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 divide-y divide-gray-200 focus:outline-none">
            <div className="py-1">
              {renderMenuItem("View profile", "profile")}
            </div>
            <div className="py-1">
              <p className="text-sm text-gray-500 py-2 px-4">Projects</p>
              {renderMenuItem(
                "Switch project",
                Routes.private.user.switchProject
              )}
              {renderMenuItem(
                "Create new project",
                Routes.private.user.createProject
              )}
            </div>
            <div className="py-1">{renderMenuItem("Logout ", "#", logout)}</div>
          </Menu.Items>
        </Menu>
      </div>
    </>
  );
};
