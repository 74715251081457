import { ChevronRightIcon, EyeIcon } from "@heroicons/react/outline";
import { PageTitle } from "../../components/PageTitle";
import { useCallback, useEffect, useState } from "react";
import { useArchitectApi, useNotifications, useProjects } from "../../hooks";
import { ArchitectRecord } from "../../types";
import { InfoModal } from "../../components/Modal";
import { Input, StandaloneSelect } from "../../components/Base/Form";
import { useForm } from "react-hook-form";

export const DataExplorer = () => {
  const {
    selectedProject,
    selectedProjectSchema,
    selectedTable,
    setSelectedTable,
  } = useProjects();

  const { renderNotification } = useNotifications();
  const architectApi = useArchitectApi(selectedProject, selectedTable);
  const [data, setData] = useState<ArchitectRecord[]>([]);
  const [recentlyViewedModels, setRecentlyViewedModels] = useState<any[]>([]);
  const [recordModalOpen, setRecordModalOpen] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();
  const [filterVisible, setFiltersVisible] = useState(false);

  const reloadData = useCallback(() => {
    architectApi?.getAll().then((result) => {
      if (result.success && result.data) {
        setData(result.data);
      }
    });
  }, [architectApi]);
  useEffect(() => {
    reloadData();
  }, [architectApi, reloadData]);

  useEffect(() => {
    const temporaryModels = selectedProjectSchema?.slice(0, 4) || [];
    setRecentlyViewedModels(
      temporaryModels.map((model) => ({
        title: model,
        initials: model[0],
        totalMembers: "",
        pinned: true,
        bgColorClass: "bg-red",
      }))
    );
  }, [selectedProjectSchema]);

  function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(" ");
  }

  const deleteRecord = (id: string) => {
    console.log(selectedTable, id);
    architectApi?.delete(id).then(() => {
      reloadData();
      renderNotification({
        type: "success",
        description: "Record successfully deleted",
        message: "Done",
      });
    });
  };

  const toggleFilters = () => {
    setFiltersVisible(!filterVisible);
    if (filterVisible) {
      reloadData();
    }
  };

  const tableHeading = (title: string) => (
    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      <span className="lg:pl-2">{title}</span>
    </th>
  );

  const { control, handleSubmit } = useForm();

  const onSubmit = (filterData: { filter: string }) => {
    architectApi?.getAll(`?${filterData.filter}`).then((result) => {
      if (result.success && result.data) {
        setData(result.data);
      }
    });
  };
  return (
    <>
      <PageTitle>Data Explorer</PageTitle>
      <div className="px-4 mt-6 sm:px-6 lg:px-8">
        <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
          Recently viewed models
        </h2>
        <ul
          role="list"
          className="grid grid-cols-1 gap-4 sm:gap-6 sm:grid-cols-2 xl:grid-cols-4 mt-3"
        >
          {recentlyViewedModels.map((model) => (
            <li
              key={model.title}
              onClick={() => setSelectedTable(model.title)}
              className="relative col-span-1 flex shadow-sm rounded-md capitalize cursor-pointer"
            >
              <div
                className={classNames(
                  model.bgColorClass,
                  "flex-shrink-0 flex items-center justify-center w-16 text-white text-sm font-medium rounded-l-md capitalize"
                )}
              >
                {model.initials}
              </div>
              <div className="flex-1 flex items-center justify-between border-t border-r border-b border-gray-200 bg-white rounded-r-md truncate">
                <div className="flex-1 px-4 py-2 text-sm truncate">
                  <a
                    href="#"
                    className="text-gray-900 font-medium hover:text-gray-600"
                  >
                    {model.title}
                  </a>
                  <p className="text-gray-500">{model.totalMembers} Records</p>
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>

      {/* Projects list (only on smallest breakpoint) */}
      <div className="mt-10 sm:hidden">
        <div className="px-4 sm:px-6">
          <h2 className="text-gray-500 text-xs font-medium uppercase tracking-wide">
            Records
          </h2>
        </div>
        <ul
          role="list"
          className="mt-3 border-t border-gray-200 divide-y divide-gray-100"
        >
          {data.map((record: ArchitectRecord) => (
            <li key={record.id}>
              <a
                href="#"
                className="group flex items-center justify-between px-4 py-4 hover:bg-gray-50 sm:px-6"
              >
                <span className="flex items-center truncate space-x-3">
                  <span className="font-medium truncate text-sm leading-6">
                    {record.id}
                  </span>
                </span>
                <ChevronRightIcon
                  className="ml-4 h-5 w-5 text-gray-400 group-hover:text-gray-500"
                  aria-hidden="true"
                />
              </a>
            </li>
          ))}
        </ul>
      </div>

      {/* Projects table (small breakpoint and up) */}
      <div className="hidden mt-8 sm:block">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <div className="flex justify-between content-center align-middle border-t px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider">
            <div>
              <span className="lg:pl-2 uppercase">Records</span>
              <p className="lg:pl-2 text-xs text-gray-400">
                Showing top 100 results matching criteria
              </p>
            </div>
            <div className={"flex"}>
              <div className="w-72">
                {selectedTable ? (
                  <StandaloneSelect
                    value={selectedTable}
                    onChange={setSelectedTable}
                    items={selectedProjectSchema}
                  />
                ) : null}
              </div>
              <button
                type="button"
                onClick={toggleFilters}
                className="inline-flex items-center ml-2 px-4 py-1.5 border border-gray-300 shadow-sm text-xs font-medium rounded rounded-lg text-gray-600 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                Filter records
              </button>
            </div>
          </div>

          {filterVisible && (
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="w-full content-center align-middle border-t px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider"
            >
              <div className="grid grid-cols-12 gap-1">
                <div className="col-span-12">
                  <Input
                    control={control}
                    name="filter"
                    label={`Filter ${selectedTable}`}
                  />
                </div>
              </div>
            </form>
          )}
          <table className="min-w-full">
            <thead>
              <tr className="border-t border-gray-200">
                {tableHeading("id")}
                {tableHeading("Created At")}
                {tableHeading("Updated At")}
                {tableHeading("View")}
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-gray-100">
              {data.map((record: ArchitectRecord) => (
                <tr
                  key={record.id}
                  onClick={() => {
                    setSelectedRecord(record);
                    setRecordModalOpen(true);
                  }}
                >
                  <td className="px-6 py-3 max-w-0 w-full  whitespace-nowrap text-sm font-medium text-gray-900">
                    <div className="flex items-center space-x-3 lg:pl-2">
                      <a href="#" className="truncate hover:text-gray-600">
                        <span>{record.id} </span>
                      </a>
                    </div>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                    <span>{record.createdAt}</span>
                  </td>
                  <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                    <span>{record.updatedAt}</span>
                  </td>
                  <td className="px-6 py-3 flex justify-center text-gray-500">
                    <EyeIcon width={20} className="cursor-pointer" />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <InfoModal
        data={selectedRecord}
        open={recordModalOpen}
        deleteRecord={deleteRecord}
        close={() => {
          setRecordModalOpen(false);
        }}
      />
    </>
  );
};
