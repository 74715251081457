import { useConfig } from "./useConfig";
import { AuthService, ProjectsService } from "../services";
import { useAuth } from "./useAuth";
import { useMemo } from "react";

export function useApi() {
  const { user } = useAuth();
  const { apiUrl } = useConfig();

  const token = useMemo(
    () => (user ? user.authorization.token : undefined),
    [user]
  );
  return {
    authService: useMemo(() => new AuthService(apiUrl), [apiUrl]),
    projectService: useMemo(
      () => new ProjectsService(apiUrl, token),
      [apiUrl, token]
    ),
  };
}
