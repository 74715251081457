import React, { FC } from "react";
import { LoginForm } from "../../components/LoginForm";
import { Page } from "../../templates";
import { FullPageWithLeftCover } from "../../templates/Page/FullPageWithLeftCover";

export const Login: FC = () => {
  return (
    <Page template={FullPageWithLeftCover}>
      <LoginForm />
    </Page>
  );
};
