import { Project, ProjectConfiguration } from "../types";
import { useEffect, useMemo, useState } from "react";
import { useApi } from "./useApi";
import { ArchitectService } from "../services/ArchitectService";

export function useArchitectApi(
  project?: Project,
  collection?: string,
  nestedProperty?: string,
  useAdminApi?: boolean
) {
  const { projectService } = useApi();
  const [developerToken, setDeveloperToken] = useState<string>();

  useEffect(() => {
    if (project) {
      projectService
        .get<ProjectConfiguration>(project.id, "configuration")
        .then((response) => {
          setDeveloperToken(
            response.success ? response.data?.developerKey : undefined
          );
        });
    }
  }, [project, projectService]);

  return useMemo(() => {
    if (project && developerToken) {
      if (collection) {
        if (nestedProperty) {
          return new ArchitectService(
            `https://${project.projectDomain}/api/${collection}/${nestedProperty}`,
            developerToken
          );
        }
        return new ArchitectService(
          `https://${project.projectDomain}/api/${collection}`,
          developerToken
        );
      } else {
        if (useAdminApi) {
          return new ArchitectService(
            `https://${project.projectDomain}/${nestedProperty}`,
            developerToken
          );
        }
      }
    } else {
      return undefined;
    }
  }, [project, developerToken, collection, nestedProperty]);
}
