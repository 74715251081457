import { FC, useEffect, useState } from "react";
import { Button, Input } from "../../../../components/Base/Form";
import { useForm } from "react-hook-form";
import { Notification, Project } from "../../../../types";
import { useNotifications, useProjects } from "../../../../hooks";
import { projectDefaults } from "./config/projectDefaults";
import { projectResolver } from "./config/projectResolver";

export const General: FC = () => {
  const { selectedProject, updateProject } = useProjects();
  const { renderNotification } = useNotifications();
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm<Project>({
    resolver: projectResolver,
    defaultValues: projectDefaults,
  });

  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    reset(selectedProject);
  }, [selectedProject, reset]);

  const attemptProjectUpdate = async (data: Project) => {
    setIsLoading(true);
    const result = await updateProject(data);
    if (result.success) {
      renderNotification({
        type: "success",
        message: "Updated!",
        description: "Project is updated successfully!",
      } as Notification);
      setIsLoading(false);
    }
  };
  const onSubmit = handleSubmit(attemptProjectUpdate);

  return (
    <form>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              General project settings
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Edit project information and settings
            </p>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                control={control}
                name={"name"}
                label={"Project name"}
                errors={errors}
              />
            </div>
            <div className="col-span-6 sm:col-span-3">
              <Input
                control={control}
                name={"appId"}
                disabled
                label={"Application ID"}
              />
            </div>
          </div>
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end">
          <Button onClick={onSubmit} type="submit" isLoading={isLoading}>
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};
