import { BaseHttpService } from "./BaseHttpService";
import {
  ApiResponse,
  CreateAccountRequest,
  EmailAuthRequest,
  User,
} from "../types";
import axios, { AxiosResponse } from "axios";

export class AuthService extends BaseHttpService {
  createAccount(data: CreateAccountRequest): Promise<ApiResponse<User>> {
    return this.api
      .post<CreateAccountRequest, AxiosResponse<User>>("/users", data)
      .then((response) => {
        return {
          success: true,
          data: response.data,
          status: response.status,
        };
      })
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          return {
            success: false,
            status: e.response?.status || 400,
          };
        }
        throw e;
      });
  }

  authenticate(data: EmailAuthRequest): Promise<ApiResponse<User>> {
    const body = {
      ...data,
      provider: "email",
    };
    return this.api
      .post<EmailAuthRequest, AxiosResponse<User>>("users/authenticate", body)
      .then((response) => {
        return {
          success: true,
          data: response.data,
          status: response.status,
        };
      })
      .catch((e) => {
        if (axios.isAxiosError(e)) {
          return {
            success: false,
            status: e.response?.status || 400,
          };
        }
        throw e;
      });
  }
}
