import { ErrorAlert } from "./ErrorAlert";
import React, { FC } from "react";
import { Routes } from "../../routes";

export const EmailAlreadyTakenAlert: FC = () => (
  <ErrorAlert title={"Hmm, this email has already been taken."}>
    If it was you please login{" "}
    <a href={Routes.public.login} className="text-red-900">
      here
    </a>{" "}
    or choose another email address.
  </ErrorAlert>
);
