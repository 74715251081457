import { FC, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/outline";
import Highlight, { defaultProps } from "prism-react-renderer";
import "./infoModalStyle.css";
import { theme } from "./codeStyle";
type Props = {
  open: boolean;
  close: () => void;
  data?: any;
  deleteRecord: (id: string) => void;
};
export const InfoModal: FC<Props> = ({
  open = false,
  close,
  data = {},
  deleteRecord,
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className=" inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
              <div className="mb-5 w-full flex justify-between">
                <div className="flex items-center">
                  <button
                    type="button"
                    className="mt-3 w-[60px] inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={close}
                  >
                    <ArrowLeftIcon width={20} />
                  </button>
                  <span className="ml-3 text-xl">Record details</span>
                </div>
                <button
                  type="button"
                  className="w-full w-[60px] inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red text-base font-medium text-white hover:bg-red focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red sm:col-start-2 sm:text-sm"
                  onClick={() => {
                    deleteRecord(data.id);
                    close();
                  }}
                >
                  <TrashIcon width={20} />
                </button>
              </div>
              <div
                className={
                  "max-h-[800px] h-screen overflow-scroll text-sm leading-[24px]"
                }
              >
                <Highlight
                  {...defaultProps}
                  theme={theme}
                  language={"json"}
                  code={JSON.stringify(data, null, 2)}
                >
                  {({
                    className,
                    style,
                    tokens,
                    getLineProps,
                    getTokenProps,
                  }) => (
                    <pre className={className} style={style}>
                      {tokens.map((line, i) => (
                        <div className={"flex"}>
                          <span className="mr-7 text-gray-300">{i + 1}</span>
                          <div {...getLineProps({ line, key: i })}>
                            {line.map((token, key) => (
                              <span {...getTokenProps({ token, key })} />
                            ))}
                          </div>
                        </div>
                      ))}
                    </pre>
                  )}
                </Highlight>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
