import React, { FC } from "react";
import { AppleIcon, GithubIcon, GoogleIcon } from "../../assets/icons";

type Props = {
  icon: "google" | "apple" | "github";
  disabled?: boolean;
};
export const SocialLoginButton: FC<Props> = ({ icon }) => {
  const renderIcon = () => {
    switch (icon) {
      case "google":
        return <GoogleIcon height={20} />;
      case "apple":
        return <AppleIcon height={20} />;
      case "github":
        return <GithubIcon height={20} />;
    }
  };
  return (
    <div>
      <a
        href="#"
        className="w-full inline-flex justify-center rounded-md py-2 px-4 border border-gray-300 shadow-sm bg-white text-sm font-medium text-gray-500 hover:bg-gray-50"
      >
        <span className="sr-only">Sign in with Google</span>
        {renderIcon()}
      </a>
    </div>
  );
};
