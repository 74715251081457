import { FC, useEffect, useState } from "react";
import { Subtitle } from "../Base/Typography";
import { CheckIcon } from "@heroicons/react/solid";
import { ProjectInfoForm } from "../ProjectInfoForm";
import { ProjectPlanForm } from "../ProjectPlanForm";
import { Project } from "../../types";
import { useAuth, useProjects } from "../../hooks";
import { ProjectReviewForm } from "../ProjectReviewForm";
import { CreateProjectLoader } from "../../assets/icons";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { AnimatePresence, motion } from "framer-motion";

const steps = [
  { id: "01", name: "Project info" },
  { id: "02", name: "Pick a plan" },
  { id: "03", name: "Summary" },
];

export const CreateProjectForm: FC = () => {
  const { user } = useAuth();
  const { createProject } = useProjects();
  const history = useHistory();
  const [currentStep, setCurrentStep] = useState(0);
  const [lastCompletedStep, setLastCompletedStep] = useState(0);
  const [projectCreationInProgress, setProjectCreationInProgress] =
    useState(false);

  const [project, setProject] = useState<Project>({
    userId: user?.id,
  } as Project);

  const nextStep = (data: Partial<Project>) => {
    setProject({
      ...project,
      ...data,
    });
    setLastCompletedStep(
      currentStep > lastCompletedStep ? currentStep : lastCompletedStep
    );
    setCurrentStep(currentStep + 1);
  };
  const attemptToCreateProject = async () => {
    setProjectCreationInProgress(true);
    delete project.planId;
    const result = await createProject(project);
    if (result.success) {
      history.replace(Routes.private.project.home);
    } else {
      console.log("error");
    }
  };

  useEffect(() => {
    console.log(project);
  }, [project]);

  return (
    <AnimatePresence>
      {projectCreationInProgress ? (
        <motion.div
          key="loading"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.3 }}
          exit={{ opacity: 0 }}
        >
          <Subtitle className="mb-6 text-center">
            Your project is being created
          </Subtitle>
          <img className="w-24 mx-auto" src={CreateProjectLoader} alt="" />
        </motion.div>
      ) : (
        <motion.div
          key="form"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
          exit={{ opacity: 0 }}
        >
          <Subtitle className="mb-6">Create a project</Subtitle>
          <nav aria-label="Progress">
            <ol
              role="list"
              className="border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0 cursor-pointer"
            >
              {steps.map((step, stepIndex) => (
                <li key={step.name} className="relative md:flex-1 md:flex">
                  {stepIndex <= lastCompletedStep ? (
                    <div
                      onClick={() => setCurrentStep(stepIndex)}
                      className="group flex items-center w-full"
                    >
                      <span className="px-6 py-4 flex items-center text-sm font-medium">
                        <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center bg-blue-600 rounded-full group-hover:bg-blue-800">
                          <CheckIcon
                            className="w-3 h-3 text-white"
                            aria-hidden="true"
                          />
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </div>
                  ) : stepIndex === currentStep ? (
                    <div
                      className="px-6 py-4 flex items-center text-sm font-medium"
                      aria-current="step"
                    >
                      <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center border-2 border-blue-600 rounded-full">
                        <span className="text-xs text-blue-600" />
                      </span>
                      <span className="ml-4 text-sm font-medium text-blue-600">
                        {step.name}
                      </span>
                    </div>
                  ) : (
                    <div className="group flex items-center">
                      <span className="px-6 py-4 flex items-center text-sm font-medium">
                        <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center border-2 border-gray-300 rounded-full group-hover:border-gray-400">
                          <span className="text-gray-500 text-xs group-hover:text-gray-900" />
                        </span>
                        <span className="ml-4 text-sm font-medium text-gray-500 group-hover:text-gray-900">
                          {step.name}
                        </span>
                      </span>
                    </div>
                  )}

                  {stepIndex !== steps.length - 1 ? (
                    <>
                      {/* Arrow separator for lg screens and up */}
                      <div
                        className="hidden md:block absolute top-0 right-0 h-full w-5"
                        aria-hidden="true"
                      >
                        <svg
                          className="h-full w-full text-gray-300"
                          viewBox="0 0 22 80"
                          fill="none"
                          preserveAspectRatio="none"
                        >
                          <path
                            d="M0 -2L20 40L0 82"
                            vectorEffect="non-scaling-stroke"
                            stroke="currentcolor"
                            strokeLinejoin="round"
                          />
                        </svg>
                      </div>
                    </>
                  ) : null}
                </li>
              ))}
            </ol>
          </nav>
          <div>
            {currentStep === 0 && (
              <ProjectInfoForm onFormSubmitted={nextStep} project={project} />
            )}
            {currentStep === 1 && (
              <ProjectPlanForm onFormSubmitted={nextStep} project={project} />
            )}
            {currentStep === 2 && (
              <ProjectReviewForm
                onFormSubmitted={attemptToCreateProject}
                project={project}
                buttonText="Create project"
              />
            )}
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
