import { AnimatePresence, motion } from "framer-motion";
import React, { FC, useMemo } from "react";
import { Spinner } from "../../../assets/icons";

type Props = {
  isLoading?: boolean;
  variant?: "blue" | "white";
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button: FC<Props> = ({
  children,
  className,
  disabled,
  isLoading = false,
  variant = "blue",
  ...rest
}) => {
  const style = useMemo(() => {
    switch (variant) {
      case "blue":
        return "bg-blue text-white hover:bg-blue-600";
      case "white":
        return "bg-white text-blue hover:bg-gray-100";
    }
  }, [variant]);
  return (
    <button
      className={`
      relative
      flex py-2 px-4 z-0
    	border border-transparent shadow-sm
    	rounded rounded-8
    	transition-all ease-in-out duration-200
    	text-sm font-medium focus:outline-none 
    	focus:ring-2 focus:ring-offset-2 focus:ring-blue-600
    	${style}
    	${disabled && "opacity-50"} 
    	${isLoading ? "pr-14 justify-start" : "min-w-0 justify-center"}
    	${className || ""}`}
      {...rest}
    >
      {isLoading && (
        <AnimatePresence>
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <Spinner className={"absolute animate-spin right-4 h-5 w-5"} />
          </motion.div>
        </AnimatePresence>
      )}
      {children}
    </button>
  );
};
