import { FC } from "react";
import { Checkbox, Input } from "../../../components/Base/Form";
import { useForm } from "react-hook-form";
import { EmailAuthRequest } from "../../../types";
import {
  loginDefaults,
  loginResolver,
} from "../../../components/LoginForm/constants";

export const Notifications: FC = () => {
  const { handleSubmit, control } = useForm<EmailAuthRequest>({
    resolver: loginResolver,
    defaultValues: loginDefaults,
  });

  return (
    <form>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Notifications
            </h3>
            <p className="mt-1 text-sm text-gray-500">
              Pick which notifications Essentialz should send to project team
              members
            </p>
          </div>
          <fieldset className="">
            <legend className="text-base font-medium text-gray-900">
              By Email
            </legend>
            <div className="mt-4 space-y-5">
              <Checkbox
                control={control}
                name={"notifications"}
                label={"New user account created"}
                description={"Get notified when new user account is created"}
              />
              <Checkbox
                control={control}
                name={"notifications"}
                label={"Webhook failed"}
                description={
                  "Get notified when webhook receives status other then 200"
                }
              />
              <Checkbox
                control={control}
                name={"notifications"}
                label={"Function execution failed"}
                description={
                  "Get notified when function execution returns Internal Server Error 500"
                }
              />
              <Checkbox
                control={control}
                name={"notifications"}
                label={"Plan quota usage at 80%"}
                description={
                  "Get notified when allocated resources are 80% used"
                }
              />
            </div>
          </fieldset>
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
          <button
            type="submit"
            className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            Save
          </button>
        </div>
      </div>
    </form>
  );
};
