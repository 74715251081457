import React, { FC } from "react";

type Props = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLHeadingElement>,
  HTMLHeadingElement
>;
export const Subtitle: FC<Props> = ({ children, className }) => {
  return (
    <h2
      className={`mt-6 text-3xl font-extrabold text-gray-900 ${
        className || ""
      }`}
    >
      {children}
    </h2>
  );
};
