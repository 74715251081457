import { FC } from "react";
import { CreateProjectFormStepProps } from "../CreateProjectForm";
import { Button } from "../Base/Form";

type Props = CreateProjectFormStepProps;
export const ProjectReviewForm: FC<Props> = ({
  buttonText,
  project,
  onFormSubmitted,
}) => {
  const onSubmit = () => onFormSubmitted(project);
  return (
    <div>
      <div className="my-5">
        <div className="bg-white shadow overflow-hidden sm:rounded-lg">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Application information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Details about the application that will be created
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Project Name
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{project.name}</dd>
              </div>
              <div className="sm:col-span-1">
                <dt className="text-sm font-medium text-gray-500">
                  Application ID
                </dt>
                <dd className="mt-1 text-sm text-gray-900">{project.appId}</dd>
              </div>
            </dl>
          </div>
        </div>

        <div className="bg-white shadow overflow-hidden sm:rounded-lg mt-4">
          <div className="px-4 py-5 sm:px-6">
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Plan information
            </h3>
            <p className="mt-1 max-w-2xl text-sm text-gray-500">
              Pricing details for the selected plan
            </p>
          </div>
          <div className="border-t border-gray-200 px-4 py-5 sm:px-6">
            <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
              <div className="sm:col-span-2">
                <dd className="mt-2 text-sm text-gray-900">
                  <ul
                    role="list"
                    className="border border-gray-200 rounded-md divide-y divide-gray-200"
                  >
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <div className="ml-2">
                          <span className="flex-1 w-0 truncate">Starter</span>
                          <p className="text-xs text-black opacity-60">
                            Monthly billing cycle with{" "}
                            <span className="font-bold">
                              14 days free trial
                            </span>
                          </p>
                        </div>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <p className="font-medium text-blue-600 hover:text-blue-500">
                          $25.99
                        </p>
                      </div>
                    </li>
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <span className="ml-2 flex-1 w-0 truncate">
                          Tax (8%)
                        </span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <p className="font-medium text-blue-600 hover:text-blue-500">
                          $2.45
                        </p>
                      </div>
                    </li>
                    <li className="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
                      <div className="w-0 flex-1 flex items-center">
                        <span className="ml-2 flex-1 w-0 truncate">Total</span>
                      </div>
                      <div className="ml-4 flex-shrink-0">
                        <p className="font-medium text-blue-600 hover:text-blue-500">
                          $30.00
                        </p>
                      </div>
                    </li>
                  </ul>
                </dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
      <div className="flex justify-end">
        <Button onClick={onSubmit} type="submit">
          {buttonText || "Next Step"}
        </Button>
      </div>
    </div>
  );
};
