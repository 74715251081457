import { FC, useEffect, useState } from "react";
import { Button, Input } from "../../../../components/Base/Form";
import { useForm } from "react-hook-form";
import { ProjectConfiguration } from "../../../../types";
import { configurationDefaults } from "./config/projectDefaults";
import { useNotifications, useProjects } from "../../../../hooks";
import { Switch } from "../../../../components/Base/Form/Switch";

export const Security: FC = () => {
  const { renderNotification } = useNotifications();
  const {
    selectedProject,
    selectedProjectConfiguration,
    updateProjectConfiguration,
  } = useProjects();
  const [enabled, setEnabled] = useState(false);

  const { handleSubmit, control, reset } = useForm<ProjectConfiguration>({
    defaultValues: configurationDefaults,
  });

  useEffect(() => {
    reset(selectedProjectConfiguration);
  }, [selectedProjectConfiguration]);

  const attemptProjectConfigurationUpdate = async (
    data: ProjectConfiguration
  ) => {
    if (selectedProject) {
      const response = await updateProjectConfiguration(selectedProject, data);
      if (response.success) {
        renderNotification({
          type: "success",
          message: "Updated!",
          description: "Schema lock updated successfully",
        });
      }
    }
  };

  const onSubmit = handleSubmit(attemptProjectConfigurationUpdate);

  return (
    <form>
      <div className="shadow sm:rounded-md sm:overflow-hidden">
        <div className="bg-white  py-6 px-4 space-y-6 sm:p-6">
          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Security Settings
            </h3>
            <p className="max-w-[600px] mt-1 text-sm text-gray-500">
              Keep these keys secure all the time since they allow access to
              resources outside of the policy definitions.
            </p>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                control={control}
                name={"developerKey"}
                disabled
                label={"Developer Key"}
              />
            </div>
          </div>

          <div className="grid grid-cols-6 gap-6">
            <div className="col-span-6 sm:col-span-3">
              <Input
                control={control}
                name={"adminKey"}
                disabled
                label={"Admin API Key"}
              />
            </div>
          </div>

          <div>
            <h3 className="text-lg leading-6 font-medium text-gray-900">
              Schema Lock
            </h3>
            <p className="max-w-[600px] mt-1 text-sm text-gray-500">
              Activating this flag will make your schema locked for any changes
              meaning that new tables will not be able to be created on the fly
              for example.
            </p>
          </div>

          <Switch control={control} name="schemaLocked" />
        </div>

        <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end">
          <Button onClick={onSubmit} type="submit">
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};
