import { useState } from "react";
import { MenuAlt1Icon } from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";
import { Routes } from "../../../routes";

import { DataExplorer } from "../DataExplorer";
import { SwitchProject } from "../SwitchProject";
import { Settings } from "../Settings";
import { SideMenu } from "../../../components/SideMenu";
import { CreateProject } from "../CreateProject";
import { Policies } from "../Policies";
import { SchemaDefinitions } from "../SchemaDefinition/SchemaDefinitions";
import { Webhooks } from "../Webhooks";
import { Overview } from "../Overview";
import { Functions } from "../Functions";
import { DnsManagement } from "../DnsManagement";
import { useProjects } from "../../../hooks";

export const Root = () => {
  const { path } = useRouteMatch();
  const { projects } = useProjects();
  const [sidebarOpen, setSidebarOpen] = useState(false);

  return (
    <>
      {projects && (
        <div className="min-h-full">
          <SideMenu sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
          {/* Main column */}
          <div className="lg:pl-64 flex flex-col">
            <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden">
              <button
                type="button"
                className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
                onClick={() => setSidebarOpen(true)}
              >
                <span className="sr-only">Open sidebar</span>
                <MenuAlt1Icon className="h-6 w-6" aria-hidden="true" />
              </button>
              <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
                <div className="flex-1 flex">
                  <form className="w-full flex md:ml-0" action="#" method="GET">
                    <label htmlFor="search-field" className="sr-only">
                      Search
                    </label>
                    <div className="relative w-full text-gray-400 focus-within:text-gray-600">
                      <div className="absolute inset-y-0 left-0 flex items-center pointer-events-none">
                        <SearchIcon className="h-5 w-5" aria-hidden="true" />
                      </div>
                      <input
                        id="search-field"
                        name="search-field"
                        className="block w-full h-full pl-8 pr-3 py-2 border-transparent text-gray-900 placeholder-gray-500 focus:outline-none focus:ring-0 focus:border-transparent focus:placeholder-gray-400 sm:text-sm"
                        placeholder="Search"
                        type="search"
                      />
                    </div>
                  </form>
                </div>
              </div>
            </div>
            <main className="flex-1">
              <Switch>
                {projects.length == 0 ? (
                  <>
                    <Redirect to={Routes.private.user.createProject} />
                    <Route
                      path={Routes.private.user.createProject}
                      component={CreateProject}
                    />
                  </>
                ) : (
                  <>
                    <Route exact path={path} component={Overview} />
                    <Route
                      exact
                      path={Routes.private.project.dataExplorer}
                      component={DataExplorer}
                    />
                    <Route
                      exact
                      path={Routes.private.user.switchProject}
                      component={SwitchProject}
                    />

                    <Route
                      path={Routes.private.project.settings.general}
                      component={Settings}
                    />
                    <Route
                      path={Routes.private.project.policies}
                      component={Policies}
                    />
                    <Route
                      path={Routes.private.project.schemaDefinition}
                      component={SchemaDefinitions}
                    />
                    <Route
                      path={Routes.private.project.webhooks}
                      component={Webhooks}
                    />
                    <Route
                      path={Routes.private.project.functions}
                      component={Functions}
                    />
                    <Route
                      path={Routes.private.project.dnsManagement}
                      component={DnsManagement}
                    />
                    <Route
                      exact
                      path={Routes.private.user.createProject}
                      component={CreateProject}
                    />
                  </>
                )}
              </Switch>
            </main>
          </div>
        </div>
      )}
    </>
  );
};
