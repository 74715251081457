import { FullPageWithCenteredContent } from "../../../templates/Page/FullPageWithCenteredContent";
import { Page } from "../../../templates";
import { CreateProjectForm } from "../../../components/CreateProjectForm";

export const CreateProject = () => {
  return (
    <Page template={FullPageWithCenteredContent}>
      <CreateProjectForm />
    </Page>
  );
};
