import { FC, useEffect, useState } from "react";
import { PageTitle } from "../../../components/PageTitle";
import { EmptyWebhooksView } from "./index";
import { PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { Webhook } from "../../../types";
import { useArchitectApi, useProjects } from "../../../hooks";
import { CreateWebhookModal } from "../../../components/Modal";

const colorMappings: Record<string, string> = {
  updated: "bg-yellow-300",
  deleted: "bg-red-400",
  created: "bg-green-500",
};
const textColorMappings: Record<string, string> = {
  updated: "text-black",
  deleted: "text-white",
  created: "text-white",
};

export const Webhooks: FC = () => {
  const [createWebhookModalOpen, setCreateWebhookModalOpen] = useState(false);
  const { selectedProject } = useProjects();
  const architectApi = useArchitectApi(
    selectedProject,
    undefined,
    "webhooks",
    true
  );
  const [data, setData] = useState<Webhook[]>([]);

  useEffect(() => {
    console.log(selectedProject);
    architectApi
      ?.getAll<Webhook>()
      .then((result) =>
        setData(result.success && result.data ? result.data : [])
      );
  }, [architectApi, selectedProject]);

  const tableHeading = (title: string) => (
    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      <span className="lg:pl-2">{title}</span>
    </th>
  );

  return (
    <>
      <PageTitle>Webhooks</PageTitle>
      <>
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <div className="flex justify-between content-center align-middle px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider">
            <div>
              <span className="lg:pl-2 uppercase">Webhook items</span>
              <p className="lg:pl-2 text-xs text-gray-400">
                Showing top 100 results matching criteria
              </p>
            </div>
            <div className={"flex"}>
              <button
                onClick={() => setCreateWebhookModalOpen(true)}
                type="button"
                className="inline-flex items-center transition-all ml-2 px-4 py-[9px] border border-green-300 shadow-sm text-xs font-medium rounded rounded-lg text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PlusIcon width={16} />
                Add new webhook
              </button>
            </div>
          </div>

          {data.length > 0 ? (
            <table className="min-w-full">
              <thead>
                <tr className="border-t border-gray-200">
                  {tableHeading("Webhook")}
                  {tableHeading("Created At")}
                  {tableHeading("Delete")}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {data.map((record: Webhook) => {
                  const entity = record.eventType.split("_")[0];
                  const action = record.eventType.split("_")[1];
                  return (
                    <tr key={record.id}>
                      <td className="px-6 py-3 max-w-0 w-full  whitespace-nowrap text-sm font-medium text-gray-900">
                        <div className="flex items-center space-x-3 lg:pl-2">
                          <div className="flex items-center w-full">
                            <div className="text-gray-400">When a</div>
                            <div className="mx-2 px-2 py-1 rounded-md bg-gray-200 text-gray-700">
                              {entity}
                            </div>
                            <div className="text-gray-400">is</div>
                            <div
                              className={`mx-2 px-2 py-1 rounded-md ${colorMappings[action]} ${textColorMappings[action]}`}
                            >
                              {action}
                            </div>
                            <div className="text-gray-400">notify</div>
                            <div className="mx-2 px-2 py-1 rounded-md bg-gray-200 text-gray-700">
                              {record.webhookUrl}
                            </div>
                          </div>
                        </div>
                      </td>
                      <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                        <span>{record.createdAt}</span>
                      </td>
                      <td className="py-3 text-gray-500">
                        <TrashIcon
                          width={20}
                          height={20}
                          className="cursor-pointer mx-auto text-red-400"
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          ) : (
            <EmptyWebhooksView />
          )}
        </div>
        <CreateWebhookModal
          open={createWebhookModalOpen}
          close={() => setCreateWebhookModalOpen(false)}
        />
      </>
    </>
  );
};
