import { createContext, FC, useCallback, useEffect, useState } from "react";
import { ApiResponse, ProjectConfiguration, Project } from "../types";
import { useApi } from "../hooks";
import camelcaseKeys from "camelcase-keys";

export type ProjectsContextType = {
  projects?: Project[];
  selectedTable?: string;
  setSelectedTable: (table: string) => void;
  selectedProject?: Project;
  setSelectedProject: (project: Project) => void;
  selectedProjectSchema?: string[];
  selectedProjectConfiguration?: ProjectConfiguration;
  getSchema: (project: Project) => void;
  getConfiguration: (project: Project) => void;
  createProject: (project: Project) => Promise<ApiResponse<Project>>;
  updateProject: (project: Project) => Promise<ApiResponse<Project>>;
  updateProjectConfiguration: (
    project: Project,
    configuration: ProjectConfiguration
  ) => Promise<ApiResponse<ProjectConfiguration>>;
};

export const ProjectsContext = createContext<ProjectsContextType>(
  {} as ProjectsContextType
);

const SelectedProjectKey = "e8z_project";

export const ProjectsProvider: FC = ({ children }) => {
  const { projectService } = useApi();
  const [selectedTable, setSelectedTable] = useState<string>();
  const [projects, setProjects] = useState<Project[]>();
  const [selectedProject, setSelectedProject] = useState<Project>();
  const [selectedProjectConfiguration, setSelectedProjectConfiguration] =
    useState<ProjectConfiguration>();
  const [selectedProjectSchema, setSelectedProjectSchema] =
    useState<string[]>();

  const getSchema = useCallback(
    async (project: Project) => {
      const response = await projectService.get<string[]>(
        project.id,
        "info/schema"
      );
      setSelectedProjectSchema(response.success ? response.data : []);
    },
    [projectService]
  );

  const getConfiguration = useCallback(
    async (project: Project) => {
      const response = await projectService.get<ProjectConfiguration>(
        project.id,
        "configuration"
      );
      setSelectedProjectConfiguration(
        response.success ? response.data : undefined
      );
    },
    [projectService]
  );
  const createProject = useCallback(
    async (project: Project) => {
      const response = await projectService.create(project);
      if (response.success && response.data) {
        setSelectedProject(response.data);
      }
      return response;
    },
    [projectService]
  );

  const updateProject = useCallback(
    async (project: Project) => {
      const response = await projectService.update(project);
      if (response.success && response.data) {
        setSelectedProject(response.data);
      }
      return response;
    },
    [projectService]
  );

  const updateProjectConfiguration = useCallback(
    async (project: Project, configuration: ProjectConfiguration) => {
      const { schemaLocked } = configuration;
      const response = await projectService.update<ProjectConfiguration>(
        project,
        "configuration",
        { schemaLocked } as ProjectConfiguration
      );
      if (response.success && response.data) {
        setSelectedProjectConfiguration(response.data);
      }
      return response;
    },
    [projectService]
  );

  useEffect(() => {
    const project = localStorage.getItem(SelectedProjectKey);
    setSelectedProject(project ? JSON.parse(project) : undefined);

    projectService.getAll().then((response) => {
      if (response.success) {
        const fetchedProjects = response.data as Project[];
        setProjects(fetchedProjects);
        if (!project) {
          setSelectedProject(fetchedProjects[0]);
        }
      }
    });
  }, [projectService]);

  useEffect(() => {
    if (selectedProject) {
      localStorage.setItem(SelectedProjectKey, JSON.stringify(selectedProject));
      getSchema(selectedProject);
      getConfiguration(selectedProject);
    }
  }, [getConfiguration, getSchema, selectedProject]);

  useEffect(() => {
    setSelectedTable(selectedProjectSchema ? selectedProjectSchema[0] : "");
  }, [selectedProjectSchema]);
  return (
    <ProjectsContext.Provider
      value={{
        projects,
        selectedTable,
        setSelectedTable,
        createProject,
        updateProject,
        updateProjectConfiguration,
        selectedProject,
        setSelectedProject,
        selectedProjectSchema,
        selectedProjectConfiguration,
        getSchema,
        getConfiguration,
      }}
    >
      {children}
    </ProjectsContext.Provider>
  );
};
