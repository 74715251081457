import { BadCredentialsAlert, ServerErrorAlert } from "../../Alert";
import React from "react";

export const errorAlert = (status: number): React.ReactNode => {
  switch (status) {
    case 401:
    case 404:
      return <BadCredentialsAlert />;
    case 500:
    case 501:
    case 502:
    case 503:
    case 504:
      return <ServerErrorAlert />;
  }
};
