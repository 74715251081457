import { RestService } from "./RestService";
import { ArchitectRecord } from "../types";

export class ArchitectService extends RestService<ArchitectRecord> {
  constructor(apiUrl: string, token?: string) {
    const developerKeyAuthenticationHeaderCallback = (
      developerApiKey: string
    ) => ({
      X_DEVELOPER_API_KEY: developerApiKey,
    });
    super(apiUrl, "", token, developerKeyAuthenticationHeaderCallback);
  }
}
