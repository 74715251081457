import { BaseHttpService } from "./BaseHttpService";
import axios, { AxiosResponse } from "axios";
import { ApiResponse, DataRecord } from "../types";

export abstract class RestService<
  T extends DataRecord
> extends BaseHttpService {
  private readonly route: string;

  protected constructor(
    apiUrl: string,
    route: string,
    token?: string,
    authorizationHeaderCallback?: (token: string) => Record<string, string>
  ) {
    super(apiUrl, token, authorizationHeaderCallback);
    this.route = route;
  }

  private handleError(e: Error) {
    if (axios.isAxiosError(e)) {
      return {
        success: false,
        status: e.response?.status || 400,
      };
    }
    throw e;
  }

  private handleSuccess<R = T>(response: AxiosResponse<R>) {
    return {
      success: true,
      data: response.data,
      status: response.status,
    };
  }

  getAll<R = T>(q?: string): Promise<ApiResponse<R[]>> {
    return this.api
      .get<R[]>(`${this.route}${q || ""}`)
      .then((response) => {
        return this.handleSuccess<R[]>(response);
      })
      .catch(this.handleError);
  }

  get<R = T>(
    id: number | string,
    nestedProperty?: string
  ): Promise<ApiResponse<R>> {
    const urlAppendix = nestedProperty ? `/${nestedProperty}` : "";
    return this.api
      .get<R>(`${this.route}/${id}${urlAppendix}`)
      .then((response) => this.handleSuccess<R>(response))
      .catch(this.handleError);
  }

  create(data: T): Promise<ApiResponse<T>> {
    return this.api
      .post<T>(this.route, data)
      .then(this.handleSuccess)
      .catch(this.handleError);
  }

  update<R = T>(
    data: T,
    nestedProperty?: string,
    nestedData?: R,
    skipCasingNormalization?: boolean
  ): Promise<ApiResponse<R>> {
    const urlAppendix = nestedProperty ? `/${nestedProperty}` : "";
    const headers = skipCasingNormalization
      ? { skipCasingNormalization: "skip" }
      : undefined;
    return this.api
      .put<R>(`${this.route}/${data.id}${urlAppendix}`, nestedData || data, {
        headers,
      })
      .then((response) => this.handleSuccess<R>(response))
      .catch(this.handleError);
  }

  delete(id: string) {
    return this.api.delete(`${this.route}/${id}`);
  }
}
