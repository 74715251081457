import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "./ControlledInputProps";
import { ExclamationCircleIcon } from "@heroicons/react/solid";

type Props = {
  formatter?: (value: string) => string;
} & ControlledInputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

export const Input: FC<Props> = ({
  control,
  errors,
  name,
  label,
  description,
  formatter,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div>
          {label && (
            <label
              htmlFor={name}
              className="block text-sm font-medium text-gray-700"
            >
              {label}
            </label>
          )}
          {description && (
            <p className="my-1 text-gray-500 text-xs">{description}</p>
          )}
          <div className={label || description ? "relative mt-1" : "relative"}>
            <input
              className="appearance-none block w-full px-3 py-2 border border-gray-300 shadow-sm rounded-md placeholder-gray-400 focus:outline-none focus:ring-blue focus:border-blue sm:text-sm"
              id={name}
              name={name}
              value={field.value || ""}
              onChange={(event) => {
                if (formatter) {
                  event.target.value = formatter(event.target.value);
                }
                return field.onChange(event);
              }}
              {...rest}
            />
            {errors && errors[name] && (
              <div className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
                <p className="text-xs text-red-300 font-medium mr-2">
                  {errors[name].message}
                </p>
                <ExclamationCircleIcon
                  className="h-5 w-5 text-red-500"
                  aria-hidden="true"
                />
              </div>
            )}
          </div>
        </div>
      )}
    />
  );
};
