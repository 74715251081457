import { FC, useCallback, useEffect } from "react";
import { Button, Input } from "../Base/Form";
import { useForm } from "react-hook-form";
import { Project } from "../../types";
import { createProjectResolver } from "./config/createProjectResolver";
import { createProjectDefaults } from "./config/createProjectDefaults";
import { CreateProjectFormStepProps } from "../CreateProjectForm";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useHistory } from "react-router-dom";

type Props = CreateProjectFormStepProps;

export const ProjectInfoForm: FC<Props> = ({
  buttonText,
  onFormSubmitted,
  project,
}) => {
  const history = useHistory();
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<Project>({
    defaultValues: createProjectDefaults,
    resolver: createProjectResolver,
  });

  useEffect(() => {
    reset(project);
  }, [project, reset]);

  const onSubmit = handleSubmit(onFormSubmitted);
  const formatApplicationId = useCallback(
    (value: string) =>
      value
        .trim()
        .replace(/[^a-zA-Z_-]/g, "")
        .toLowerCase(),
    []
  );
  return (
    <form onSubmit={onSubmit}>
      <div className="my-5">
        <Input
          label="Project Name"
          control={control}
          errors={errors}
          name="name"
          description="Can be whatever you like. This value is presented only on your dashboard and CAN be changed later."
          placeholder="Your project name"
        />
      </div>
      <div className="my-5">
        <Input
          label="Application ID"
          control={control}
          errors={errors}
          name="appId"
          description="Unique identified for you application inside Essentialz ecosystem. This value will be displayed in your API url by default and CANNOT be changed later."
          placeholder="Something catchy"
          formatter={formatApplicationId}
        />
      </div>
      <div className="flex justify-between">
        <div className="flex align-middle items-center">
          <div
            onClick={() => history.goBack()}
            className="w-20 cursor-pointer items-center px-8 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            <ArrowLeftIcon width={20} />
          </div>
          <p className="ml-3 text-md">Back to dashboard</p>
        </div>
        <Button type="submit">{buttonText || "Next Step"}</Button>
      </div>
    </form>
  );
};
