export const Routes = {
  external: {
    documentation: "https://documentation.essentialz.io",
    downloadSdks: "https://documentation.essentialz.io",
  },
  public: {
    login: "/login",
    createAccount: "/start",
    resetPassword: "/reset-password",
  },
  private: {
    project: {
      home: "/",
      dataExplorer: "/data-explorer",
      policies: "/policies",
      schemaDefinition: "/schema",
      users: "/users",
      functions: "/functions",
      dnsManagement: "/dns",
      webhooks: "/webhooks",
      settings: {
        general: "/settings",
        notifications: "/settings/notifications",
        security: "/settings/security",
        billing: "/settings/billing",
      },
    },
    user: {
      profile: "/me",
      switchProject: "/switch-project",
      createProject: "/create-project",
    },
  },
};
