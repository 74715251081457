import React, { FC } from "react";
import { FullPageWithLeftCover } from "../../templates/Page/FullPageWithLeftCover";
import { Page } from "../../templates";
import { CreateAccountForm } from "../../components/CreateAccountForm";

export const CreateAccount: FC = () => {
  return (
    <Page template={FullPageWithLeftCover}>
      <CreateAccountForm />
    </Page>
  );
};
