import axios, { Axios } from "axios";
import camelcaseKeys from "camelcase-keys";
import snakecaseKeys from "snakecase-keys";

const bearerAuthorizationHeaderCallback = (token: string) => ({
  Authorization: `Bearer ${token}`,
});

const isUpdateOrCreate = (method?: string) => {
  const lowerCasedMethod = method?.toLowerCase();
  return lowerCasedMethod === "post" || lowerCasedMethod === "put";
};

export class BaseHttpService {
  protected api: Axios;

  constructor(
    baseApiUrl: string,
    token?: string,
    authorizationHeaderCallback?: (token: string) => Record<string, string>
  ) {
    const axiosConfig = {
      baseURL: baseApiUrl,
      timeout: 3000,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    };
    this.api = axios.create(axiosConfig);
    if (token) {
      const authorizationHeader = authorizationHeaderCallback
        ? authorizationHeaderCallback(token)
        : bearerAuthorizationHeaderCallback(token);

      this.api.interceptors.request.use((request) => {
        request.headers = {
          ...request.headers,
          ...authorizationHeader,
        };
        return request;
      });
    }

    this.api.interceptors.request.use((request) => {
      if (isUpdateOrCreate(request.method)) {
        if (request.headers && !request.headers.skipCasingNormalization) {
          request.data = snakecaseKeys(request.data);
        }
      }
      return request;
    });

    this.api.interceptors.response.use(
      (response) => {
        return {
          ...response,
          data: camelcaseKeys(response.data as any, { deep: true }),
        };
      },
      (error) => {
        throw error;
      }
    );
  }
}
