import React, { FC, useRef, useState } from "react";
import { Logo } from "../../assets/brand";
import { Subtitle } from "../Base/Typography";
import { Button, Input } from "../Base/Form";
import { AnimatePresence, motion } from "framer-motion";
import { errorAlert } from "../LoginForm/constants";
import { useAuth } from "../../hooks";
import { useForm } from "react-hook-form";
import { ResetPasswordRequest } from "../../types";
import { resetPasswordDefaults, resetPasswordResolver } from "./constants";
import { Routes } from "../../routes";

export const ResetPasswordForm: FC = () => {
  const { signIn, isLoading } = useAuth();

  const [authError, setAuthError] = useState<number>();
  const errorAlertRef = useRef<HTMLDivElement>(null);

  const { handleSubmit, control } = useForm<ResetPasswordRequest>({
    resolver: resetPasswordResolver,
    defaultValues: resetPasswordDefaults,
  });

  const attemptSignIn = async (data: ResetPasswordRequest) => {
    // setAuthError(undefined);
    // const response = await signIn(data);
    // if (response.success) {
    // } else {
    //   setAuthError(response.status);
    // }
  };

  const onSubmit = handleSubmit(attemptSignIn);

  return (
    <div className={`relative mx-auto w-full max-w-sm lg:w-96`}>
      <div>
        <Logo className="h-12 w-auto" />
        <Subtitle>Reset your password</Subtitle>
        <p className="mt-2">
          Or <a href={Routes.public.login}>go back to Login</a>
        </p>
      </div>

      <div className="mt-8">
        <div className="mt-6">
          <form onSubmit={onSubmit} className="space-y-6">
            <Input
              control={control}
              label={"Email address"}
              name={"email"}
              type={"email"}
            />

            <Button
              className={"w-full relative"}
              disabled={isLoading}
              isLoading={isLoading}
              type={"submit"}
            >
              Reset password
            </Button>
          </form>
        </div>
      </div>

      <AnimatePresence>
        {authError && (
          <motion.div
            ref={errorAlertRef}
            initial={{ opacity: 0, y: 50, scale: 0.8 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
          >
            {errorAlert(authError)}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
