import { FC, useCallback, useEffect, useState } from "react";
import { PageTitle } from "../../../components/PageTitle";
import { useArchitectApi, useProjects } from "../../../hooks";
import { EyeIcon, PlusIcon, TrashIcon } from "@heroicons/react/outline";
import { EmptyPoliciesView } from "./EmptyPoliciesView";
import {
  PolicyDataItem,
  PolicyDataItemComponent,
  PolicyItem,
} from "../../../types";
import { CreatePolicyModal } from "../../../components/Modal";
import { Select, StandaloneSelect } from "../../../components/Base/Form";
import { ArchitectService } from "../../../services/ArchitectService";

export const Policies: FC = () => {
  const [createPolicyModalOpen, setCreatePolicyModalOpen] = useState(false);
  const {
    selectedProject,
    selectedProjectSchema,
    setSelectedTable,
    selectedTable,
  } = useProjects();
  // const [selectedTable, setSelectedTable] = useState<string>("users");
  const architectApi = useArchitectApi(
    selectedProject,
    selectedTable,
    "policy_items"
  );
  const [data, setData] = useState<PolicyItem[]>([]);
  const [recordModalOpen, setRecordModalOpen] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<any>();

  const fetchPolicies = useCallback(() => {
    architectApi
      ?.getAll<PolicyItem>()
      .then((result) =>
        setData(result.success && result.data ? result.data : [])
      );
  }, [architectApi]);

  const removePolicy = (policyId: string) => {
    architectApi?.delete(policyId).then(fetchPolicies);
  };

  const tableHeading = (title: string) => (
    <th className="px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
      <span className="lg:pl-2">{title}</span>
    </th>
  );

  useEffect(() => {
    fetchPolicies();
  }, [architectApi, fetchPolicies]);

  const renderPolicyDataItemComponent = (
    component: PolicyDataItemComponent
  ) => {
    const { value, type } = component;
    switch (type) {
      case "current_user":
        return (
          <div className="flex text-xs">
            <div className="px-2 py-1 rounded-r-sm rounded-l-md bg-gray-200 text-gray-700">
              current user
            </div>
            <div className="ml-0.5 px-2 py-1 rounded-l-sm rounded-r-md bg-green-500 text-white">
              {value}
            </div>
          </div>
        );
      case "plain":
        return (
          <div className="flex text-xs">
            <div className="px-2 py-1 rounded-md bg-indigo-500 text-white">
              "{value}"
            </div>
          </div>
        );
      case "entity":
        return (
          <div className="flex text-xs">
            <div className="px-2 py-1 rounded-r-sm rounded-l-md bg-yellow-300 text-black">
              entity
            </div>
            <div className="ml-0.5 px-2 py-1 rounded-l-sm rounded-r-md bg-red-500 text-white">
              {value}
            </div>
          </div>
        );
    }
  };

  const mapOperatorValue = (operator: string) => {
    switch (operator) {
      case "eq":
        return "==";
      default:
        return operator;
    }
  };
  const renderPolicyDataItem = (item: PolicyDataItem, index?: number) => {
    const { left, right, operator } = item;
    return (
      <div className="flex items-center" key={index}>
        <div className="text-gray-400 mr-2">if</div>
        {renderPolicyDataItemComponent(left)}
        <div className="text-gray-400 mx-2">{mapOperatorValue(operator)}</div>
        {renderPolicyDataItemComponent(right)}
      </div>
    );
  };
  const renderPolicyCondition = (policyItem: PolicyItem) => {
    return (
      <div
        className="border border-gray-300 px-4 pt-6 pb-3 rounded-lg relative"
        key={policyItem.id}
      >
        <div className="text-xs text-gray-400 uppercase mb-2 absolute -top-2 bg-white">
          Allow {policyItem.action}
        </div>
        {policyItem.data.map((item, index, { length }) => {
          if (length === 1 || index == 0) {
            return renderPolicyDataItem(item, index);
          } else {
            return (
              <div className="flex mt-3 items-center" key={index}>
                <div className="text-gray-600 mr-2 font-bold">and</div>
                {renderPolicyDataItem(item)}
              </div>
            );
          }
        })}
      </div>
    );
  };

  return (
    <>
      <PageTitle>Policies</PageTitle>
      <div className="hidden sm:block">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <div className="flex justify-between content-center align-middle px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider">
            <div>
              <span className="lg:pl-2 uppercase">Policy items</span>
              <p className="lg:pl-2 text-xs text-gray-400">
                Showing top 100 results matching criteria
              </p>
            </div>
            <div className={"flex"}>
              <div className="w-72">
                {selectedTable ? (
                  <StandaloneSelect
                    value={selectedTable}
                    onChange={setSelectedTable}
                    items={selectedProjectSchema}
                  />
                ) : null}
              </div>
              <button
                onClick={() => setCreatePolicyModalOpen(true)}
                type="button"
                className="inline-flex items-center transition-all ml-2 px-4 py-1.5 border border-green-300 shadow-sm text-xs font-medium rounded rounded-lg text-white bg-green-500 hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              >
                <PlusIcon width={16} />
                Add new policy item
              </button>
            </div>
          </div>

          {data.length > 0 ? (
            <table className="min-w-full">
              <thead>
                <tr className="border-t border-gray-200">
                  {tableHeading("Condition")}
                  {tableHeading("Active")}
                  {tableHeading("Created At")}
                  {tableHeading("Updated At")}
                  {tableHeading("Remove")}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-100">
                {data.map((record: PolicyItem) => (
                  <tr
                    key={record.id}
                    onClick={() => {
                      setSelectedRecord(record);
                      setRecordModalOpen(true);
                    }}
                  >
                    <td className="px-6 py-3 max-w-0 w-full  whitespace-nowrap text-sm font-medium text-gray-900">
                      <div className="flex items-center space-x-3 lg:pl-2">
                        <div>{renderPolicyCondition(record)}</div>
                      </div>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <span>{record.discardedAt ? "NO" : "YES"}</span>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <span>{record.createdAt}</span>
                    </td>
                    <td className="px-6 py-3 whitespace-nowrap text-right text-sm font-medium">
                      <span>{record.updatedAt}</span>
                    </td>
                    <td className="py-3 text-gray-500">
                      <TrashIcon
                        width={20}
                        height={20}
                        onClick={() => removePolicy(record.id)}
                        className="cursor-pointer mx-auto text-red-400"
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <EmptyPoliciesView />
          )}
        </div>
      </div>
      <CreatePolicyModal
        open={createPolicyModalOpen}
        close={() => {
          setCreatePolicyModalOpen(false);
          fetchPolicies();
        }}
      />
    </>
  );
};
