import { createContext, FC, useEffect, useMemo } from "react";
import { environment, version } from "../config";

export type ConfigContextType = {
  apiUrl: string;
  version: string;
};

export const ConfigContext = createContext<ConfigContextType>(
  {} as ConfigContextType
);

export const ConfigProvider: FC = ({ children }) => {
  const applicationVersion = useMemo(() => version, []);
  const applicationEnvironmentConfiguration = useMemo(() => environment, []);

  useEffect(() => {
    console.log("Essentialz Dashboard Version: ", applicationVersion);
  }, [applicationVersion]);

  return (
    <ConfigContext.Provider
      value={{
        apiUrl: applicationEnvironmentConfiguration.apiUrl,
        version: applicationVersion,
      }}
    >
      {children}
    </ConfigContext.Provider>
  );
};
