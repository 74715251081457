import React, { FC, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { useAuth } from "../../hooks";

import { EmailAuthRequest } from "../../types";

import { Logo } from "../../assets/brand";

import { Subtitle } from "../Base/Typography";
import { Button, Checkbox, Input } from "../Base/Form";

import { errorAlert, loginDefaults, loginResolver } from "./constants";
import { AnimatePresence, motion } from "framer-motion";
import { Separator } from "../Base/Layout";
import { SocialLoginButton } from "../SocialLoginButton";
import { Routes } from "../../routes";
import { useHistory, useLocation } from "react-router-dom";
import { PrivateRouteLocationState } from "../Navigation";

export const LoginForm: FC = () => {
  const history = useHistory();
  const location = useLocation<PrivateRouteLocationState | undefined>();

  const { signIn, isLoading } = useAuth();
  const [authError, setAuthError] = useState<number>();
  const errorAlertRef = useRef<HTMLDivElement>(null);

  const { handleSubmit, control } = useForm<EmailAuthRequest>({
    resolver: loginResolver,
    defaultValues: loginDefaults,
  });

  const attemptSignIn = (data: EmailAuthRequest) => {
    setAuthError(undefined);
    signIn(data).then((response) => {
      if (response.success) {
        if (location.state) {
          history.replace(location.state?.from);
        } else {
          history.replace("/");
        }
      } else {
        setAuthError(response.status);
      }
    });
  };

  const onSubmit = handleSubmit(attemptSignIn);

  return (
    <div className={`relative mx-auto w-full max-w-sm lg:w-96`}>
      <div>
        <Logo className="h-12 w-auto" />
        <Subtitle>Sign in to your account</Subtitle>
        <p className="mt-2">
          Or{" "}
          <a href={Routes.public.createAccount}>start your 14-day free trial</a>
        </p>
      </div>

      <div className="mt-8">
        <div>
          <div>
            <p className="text-sm font-medium text-gray-700">Sign in with</p>

            <div className="mt-1 grid grid-cols-3 gap-3">
              <SocialLoginButton icon={"google"} />
              <SocialLoginButton icon={"github"} />
              <SocialLoginButton icon={"apple"} />
            </div>
          </div>

          <Separator>Or continue with</Separator>
        </div>

        <div className="mt-6">
          <form onSubmit={onSubmit} className="space-y-6">
            <Input
              control={control}
              label={"Email address"}
              name={"email"}
              type={"email"}
            />
            <Input
              control={control}
              label={"Password"}
              name={"password"}
              type={"password"}
            />

            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Checkbox
                  control={control}
                  name={"rememberMe"}
                  label={"Remember me"}
                />
              </div>

              <a href={Routes.public.resetPassword}>Forgot your password?</a>
            </div>

            <Button
              className={"w-full relative"}
              disabled={isLoading}
              isLoading={isLoading}
              type={"submit"}
            >
              Sign in
            </Button>
          </form>
        </div>
      </div>

      <AnimatePresence>
        {authError && (
          <motion.div
            ref={errorAlertRef}
            initial={{ opacity: 0, y: 50, scale: 0.8 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
          >
            {errorAlert(authError)}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
