import { FC } from "react";
import { Controller } from "react-hook-form";
import { Switch as HUISwitch } from "@headlessui/react";
import { ControlledInputProps } from "./ControlledInputProps";

type Props = ControlledInputProps;
export const Switch: FC<Props> = ({ control, name }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <HUISwitch
          checked={field.value || false}
          onChange={field.onChange}
          className={`${
            field.value ? "bg-green-500" : "bg-red"
          } relative inline-flex flex-shrink-0 h-[28px] w-[52px] border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
        >
          <span
            aria-hidden="true"
            className={`${field.value ? "translate-x-6" : "translate-x-0"}
            pointer-events-none inline-block h-[24px] w-[24px] rounded-full bg-white shadow-lg transform ring-0 transition ease-in-out duration-200`}
          />
        </HUISwitch>
      )}
    />
  );
};
