import { Subtitle } from "../../components/Base/Typography";

export const Overview = () => {
  return (
    <div className="flex w-full h-screen pt-44 flex-col items-center">
      <Subtitle>Overview</Subtitle>
      <div className="text-gray-500 mt-2 max-w-xl text-center">
        Architect is gathering data and usage statistics about your project and
        will display it here once it is ready
      </div>
    </div>
  );
};
