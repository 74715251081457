import React, { FC } from "react";
import { Page } from "../../templates";
import { ResetPasswordForm } from "../../components/ResetPasswordForm";
import { FullPageWithLeftCover } from "../../templates/Page/FullPageWithLeftCover";

export const ResetPassword: FC = () => {
  return (
    <Page template={FullPageWithLeftCover}>
      <ResetPasswordForm />
    </Page>
  );
};
