import { Subtitle } from "../../components/Base/Typography";

export const DnsManagement = () => {
  return (
    <div className="flex w-full h-screen pt-44 flex-col items-center">
      <div className="flex items-center">
        <Subtitle>DNS Management</Subtitle>
        <div className="mt-6 mx-4 text-sm font-bold text-gray-800 bg-gray-200 py-2 px-3 rounded-lg">
          Coming soon
        </div>
      </div>
      <div className="text-gray-500 mt-8 max-w-xl text-center">
        Essentialz will be featuring fully functioning DNS management system
        with{" "}
        <span className="font-bold text-blue">
          {" "}
          TLS Certificate provisioning{" "}
        </span>{" "}
        for all project on the platform. Be on the lookout for our newsletter to
        receive the news info on the features to come.
      </div>
    </div>
  );
};
