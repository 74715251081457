import React, { FC } from "react";
import { Cover } from "../../assets/decoration";

export const FullPageWithLeftCover: FC = ({ children }) => {
  return (
    <div className="min-h-screen bg-white flex">
      <div className="flex-1 flex flex-col pt-[30vh] pb-10 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24">
        {children}
      </div>
      <div className="hidden lg:block relative w-0 flex-1">
        <img
          className="absolute inset-0 h-full w-full object-cover"
          src={Cover}
        />
      </div>
    </div>
  );
};
