import { Subtitle } from "../../components/Base/Typography";

export const Functions = () => {
  return (
    <div className="flex w-full h-screen pt-44 flex-col items-center">
      <div className="flex items-center">
        <Subtitle>Functions</Subtitle>
        <div className="mt-6 mx-4 text-sm font-bold text-white bg-blue py-2 px-3 rounded-lg">
          In Beta Testing
        </div>
      </div>
      <div className="text-gray-500 mt-8 max-w-xl text-center">
        Essentialz team is working on making the serverless functions available
        for all the projects on the platform, allowing unlimited possibilities
        for functionality and platform extensions.
      </div>
      <div className="text-gray-400 px-12 py-6 mt-6 rounded-lg max-w-xl text-center bg-gray-200 p-3">
        If you want to be included in the Beta testing group please reach out to
        us via support chat
      </div>
    </div>
  );
};
