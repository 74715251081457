import { FC, Fragment, useEffect, useState } from "react";
import { PageTitle } from "../../../components/PageTitle";
import { useArchitectApi, useNotifications, useProjects } from "../../../hooks";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";
import Editor from "react-simple-code-editor";
import { theme } from "../../../components/Modal/codeStyle";
import Highlight, { defaultProps } from "prism-react-renderer";
import { Button } from "../../../components/Base/Form";
import snakecaseKeys from "snakecase-keys";
import { SchemaDefinition } from "../../../types";

export const SchemaDefinitions: FC = () => {
  const { selectedProject, selectedProjectSchema } = useProjects();
  const [selectedTable, setSelectedTable] = useState<string>("users");
  const [schema, setSchema] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { renderNotification } = useNotifications();

  const architectApi = useArchitectApi(selectedProject, selectedTable);

  useEffect(() => {
    architectApi?.get<SchemaDefinition>("definition").then((res) => {
      console.log(res.data?.schema);
      setSchema(
        res.data?.schema ? JSON.stringify(res.data.schema, null, 2) : ""
      );
    });
  }, [architectApi, selectedTable]);

  const saveSchema = async () => {
    const { additionalProperties, ...jsonSchema } = JSON.parse(schema);
    const data = {
      ...snakecaseKeys({ ...jsonSchema }),
      additionalProperties: Boolean(additionalProperties),
    };
    setIsLoading(true);
    const result = await architectApi?.update(
      { id: "definition", createdAt: "", updatedAt: "" },
      undefined,
      {
        collection_name: selectedTable,
        schema: data,
      },
      true
    );
    setIsLoading(false);
    if (result?.success) {
      renderNotification({
        type: "success",
        message: "Updated",
        description: "You schema has been updated!",
      });
    } else {
      renderNotification({
        type: "error",
        message: "Something went wrong",
        description: "You schema has NOT been updated!",
      });
    }
  };

  const highlight = (code: string) => (
    <Highlight {...defaultProps} theme={theme} code={code} language="json">
      {({ className, style, tokens, getLineProps, getTokenProps }) => (
        <Fragment>
          {tokens.map((line, i) => (
            <div className={"flex relative text-sm"}>
              <div className="text-gray-300 absolute -left-7 ">{i + 1}</div>
              <div {...getLineProps({ line, key: i })}>
                {line.map((token, key) => (
                  <span {...getTokenProps({ token, key })} />
                ))}
              </div>
            </div>
          ))}
        </Fragment>
      )}
    </Highlight>
  );

  return (
    <>
      <PageTitle>Schema definitions</PageTitle>
      <div className="hidden sm:block">
        <div className="align-middle inline-block min-w-full border-b border-gray-200">
          <div className="flex justify-between content-center align-middle px-6 py-3 border-b border-gray-200 bg-gray-50 text-left text-xs font-medium text-gray-500 tracking-wider">
            <div>
              <span className="lg:pl-2 uppercase">Schema definition items</span>
              <p className="lg:pl-2 text-xs text-gray-400">
                Showing selected model schema
              </p>
            </div>
            <div className={"flex"}>
              <div className="w-72">
                <Listbox value={selectedTable} onChange={setSelectedTable}>
                  <div className="relative">
                    <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-lg shadow-md cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                      <span className="block truncate">{selectedTable}</span>
                      <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                        <SelectorIcon
                          className="w-5 h-5 text-gray-400"
                          aria-hidden="true"
                        />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                        {selectedProjectSchema?.map((table, personIdx) => (
                          <Listbox.Option
                            key={personIdx}
                            className={({ active }) =>
                              `${
                                active
                                  ? "text-amber-900 bg-amber-100"
                                  : "text-gray-900"
                              }
                          cursor-default select-none relative py-2 pl-10 pr-4`
                            }
                            value={table}
                          >
                            {/* eslint-disable-next-line @typescript-eslint/no-shadow */}
                            {({ selected, active }) => (
                              <>
                                <span
                                  className={`${
                                    selected ? "font-medium" : "font-normal"
                                  } block truncate`}
                                >
                                  {table}
                                </span>
                                {selected ? (
                                  <span
                                    className={`${
                                      active
                                        ? "text-amber-600"
                                        : "text-amber-600"
                                    }
                                absolute inset-y-0 left-0 flex items-center pl-3`}
                                  >
                                    <CheckIcon
                                      className="w-5 h-5"
                                      aria-hidden="true"
                                    />
                                  </span>
                                ) : null}
                              </>
                            )}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </Listbox>
              </div>
            </div>
          </div>
          <div className="py-6 px-8 bg-blue flex justify-between items-center">
            <div>
              <div className="flex items-center justify-between">
                <div className="text-lg font-medium text-white">
                  {selectedTable.toUpperCase()} schema definition
                </div>
              </div>
              <div className="mt-1">
                <p className="text-sm text-indigo-300 max-w-5xl">
                  Define JSON schema for Users table model to allow only certain
                  fields to be changed or added as well as the types of fields
                  that your table record will accept. To learn more about JSON
                  schema checkout this link:{" "}
                  <a
                    className="text-white hover:text-white"
                    href="https://json-schema.org/"
                  >
                    https://json-schema.org/
                  </a>
                </p>
              </div>
            </div>
            <div>
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                onClick={saveSchema}
                variant="white"
              >
                Save schema definition
              </Button>
            </div>
          </div>
          <div className="ml-5 overflow-visible">
            <Editor
              value={schema}
              onValueChange={setSchema}
              highlight={highlight}
              padding={30}
              className="font-mono text-sm"
            />
          </div>
        </div>
      </div>
    </>
  );
};
