import { FC, Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useForm } from "react-hook-form";
import { Webhook } from "../../types";
import { Button, Input, Select } from "../Base/Form";
import { useArchitectApi, useNotifications, useProjects } from "../../hooks";
import pluralize from "pluralize";

const availableActions = ["created", "updated", "deleted"];
type WebhookFormItem = {
  id: string;
  table: string;
  action: string;
  webhookUrl: string;
};
type Props = {
  open: boolean;
  close: () => void;
};
export const CreateWebhookModal: FC<Props> = ({ open = false, close }) => {
  const { selectedProject, selectedProjectSchema } = useProjects();
  const { renderNotification } = useNotifications();
  const [isLoading, setIsLoading] = useState(false);
  const { control, handleSubmit } = useForm<WebhookFormItem>();

  const architectApi = useArchitectApi(
    selectedProject,
    undefined,
    "webhooks/subscribe",
    true
  );

  const onSubmit = handleSubmit(async (data) => {
    console.log("Data: ", data);
    console.log("Webhook: ", {
      eventType: `${pluralize.singular(data.table)}_${data.action}`,
      webhookUrl: data.webhookUrl,
    });
    setIsLoading(true);
    const webhookData = {
      eventType: `${pluralize.singular(data.table)}_${data.action}`,
      webhookUrl: data.webhookUrl,
    } as Webhook;
    const result = await architectApi?.create(webhookData);
    if (result?.success) {
      renderNotification({
        type: "success",
        message: "Success",
        description: "Webhook successfully saved!",
      });
      close();
    } else {
      renderNotification({
        type: "error",
        message: "Something went wrong",
        description: "Your webhook is not saved.",
      });
    }
  });

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={close}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className=" inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div className="mb-5 w-full flex justify-between">
                <div className="flex items-center">
                  <button
                    type="button"
                    autoFocus={false}
                    className="mt-3 w-[60px] inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                    onClick={close}
                  >
                    <ArrowLeftIcon width={20} />
                  </button>
                  <span className="ml-3 text-xl">Create webhook</span>
                </div>
              </div>
              <div className="max-h-[180px] h-screen overflow-scroll text-sm leading-[24px]">
                <form onSubmit={onSubmit}>
                  <div className="mt-5 flex items-center w-full">
                    <div className="text-gray-400">When a record in table</div>
                    <div className="ml-2 w-[140px]">
                      <Select
                        control={control}
                        name="table"
                        values={selectedProjectSchema || []}
                      />
                    </div>
                    <div className="mx-2 text-gray-400">is</div>
                    <div className="w-[140px]">
                      <Select
                        control={control}
                        name="action"
                        values={availableActions}
                      />
                    </div>
                  </div>
                  <div className="mt-4 flex items-center w-full">
                    <div className="text-gray-400">
                      send <span className="font-bold text-black">POST</span>{" "}
                      request to
                    </div>
                    <div className="mx-2 flex-1">
                      <Input
                        control={control}
                        placeholder="https://..."
                        name="webhookUrl"
                      />
                    </div>
                  </div>
                  <div className="mt-7 flex justify-end">
                    <Button
                      isLoading={isLoading}
                      disabled={isLoading}
                      type="submit"
                    >
                      Save webhook
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
