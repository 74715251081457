import React, { FC } from "react";
import { Controller } from "react-hook-form";
import { ControlledInputProps } from "./ControlledInputProps";

type Props = ControlledInputProps &
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >;

export const Checkbox: FC<Props> = ({
  control,
  errors,
  name,
  label,
  description,
  ...rest
}) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field }) => (
        <div className="flex">
          <input
            id={name}
            name={name}
            type="checkbox"
            onChange={field.onChange}
            className="mt-[2.5px] h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            {...rest}
          />
          <div className="ml-3">
            <label htmlFor={name} className="block text-sm text-gray-900">
              {label}
            </label>
            {description && <p className="text-gray-500">{description}</p>}
          </div>
        </div>
      )}
    />
  );
};
