import { FC } from "react";

import {
  CreditCardIcon,
  KeyIcon,
  BellIcon,
  UserGroupIcon,
  CogIcon,
} from "@heroicons/react/outline";
import { PageTitle } from "../../../components/PageTitle";
import {
  Route,
  Switch,
  useLocation,
  useRouteMatch,
  Link,
} from "react-router-dom";
import { Routes } from "../../../routes";
import { General } from "./General";
import { Notifications } from "./Notifications";
import { Security } from "./Security";
import { Billing } from "./Billing";

const navigation = [
  {
    name: "General",
    href: Routes.private.project.settings.general,
    icon: CogIcon,
  },
  {
    name: "Notifications",
    href: "#",
    icon: BellIcon,
    disabled: true,
  },
  {
    name: "Security",
    href: Routes.private.project.settings.security,
    icon: KeyIcon,
  },
  {
    name: "Plan & Billing",
    href: "#",
    icon: CreditCardIcon,
    disabled: true,
  },
  { name: "Team", href: "#", icon: UserGroupIcon, disabled: true },
];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

export const Settings: FC = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  return (
    <>
      <PageTitle>Settings</PageTitle>
      <div className="p-6 lg:grid lg:grid-cols-12 lg:gap-x-5">
        <aside className="py-6 px-2 sm:px-6 lg:py-0 lg:pl-0 pr-3 lg:col-span-3 border-gray-100 border-r">
          <nav className="space-y-1">
            {navigation.map((item) => (
              <Link
                key={item.name}
                to={item.href}
                className={classNames(
                  item.href === pathname
                    ? "bg-gray-50 text-indigo-700 hover:text-indigo-700 hover:bg-white"
                    : "text-gray-900 hover:text-gray-900 hover:bg-gray-50",
                  "group rounded-md px-3 py-2 flex items-center text-sm font-medium"
                )}
                aria-current={item.href === pathname ? "page" : undefined}
              >
                <item.icon
                  className={classNames(
                    item.href === pathname
                      ? "text-indigo-500 group-hover:text-indigo-500"
                      : "text-gray-400 group-hover:text-gray-500",
                    "flex-shrink-0 -ml-1 mr-3 h-6 w-6"
                  )}
                  aria-hidden="true"
                />
                <div className="flex justify-between items-center flex-1">
                  <span className="truncate">{item.name}</span>
                  {item.disabled && (
                    <span className="text-xs text-gray-400">Coming soon</span>
                  )}
                </div>
              </Link>
            ))}
          </nav>
        </aside>

        <div className="space-y-6 sm:px-6 lg:px-0 lg:col-span-9 overflow-scroll">
          <Switch>
            <Route exact path={path} component={General} />
            <Route
              exact
              path={Routes.private.project.settings.notifications}
              component={Notifications}
            />
            <Route
              exact
              path={Routes.private.project.settings.security}
              component={Security}
            />
            <Route
              exact
              path={Routes.private.project.settings.billing}
              component={Billing}
            />
          </Switch>
        </div>
      </div>
    </>
  );
};
