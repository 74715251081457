import { createContext, FC, useCallback, useEffect, useState } from "react";
import {
  User,
  ApiResponse,
  EmailAuthRequest,
  CreateAccountRequest,
} from "../types";
import { useApi } from "../hooks";

export type AuthContextType = {
  user?: User;
  isLoading: boolean;
  signIn: (data: EmailAuthRequest) => Promise<ApiResponse<User>>;
  signUp: (data: CreateAccountRequest) => Promise<ApiResponse<User>>;
  signOut: () => void;
};
export const AuthContext = createContext<AuthContextType>(
  {} as AuthContextType
);

const UserSessionKey = "e8z_user_data";

export const AuthProvider: FC = ({ children }) => {
  const { authService } = useApi();
  const [user, setUser] = useState<User>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [sessionRestoreInProgress, setSessionRestoreInProgress] =
    useState(true);

  const preserveUserSession = useCallback((data: User) => {
    localStorage.setItem(UserSessionKey, JSON.stringify(data));
  }, []);

  const clearUserSession = useCallback(() => {
    localStorage.clear();
  }, []);

  const restoreUserSession = useCallback(() => {
    setSessionRestoreInProgress(true);
    const data = localStorage.getItem(UserSessionKey);
    if (data) {
      setUser(JSON.parse(data));
    } else {
      setSessionRestoreInProgress(false);
    }
  }, []);

  const signIn = async (data: EmailAuthRequest) => {
    setLoading(true);
    const result = await authService.authenticate(data);
    setLoading(false);

    if (result.success && result.data) {
      setUser({ ...result.data });
      preserveUserSession(result.data);
      if (data.rememberMe) {
        // Add logic for this
      }
    }

    return result;
  };

  const signUp = async (data: CreateAccountRequest) => {
    setLoading(true);
    const result = await authService.createAccount(data);
    setLoading(false);
    if (result.success && result.data) {
      setUser({ ...result.data });
      preserveUserSession(result.data);
    }
    return result;
  };

  const signOut = () => {
    clearUserSession();
  };

  useEffect(() => {
    restoreUserSession();
  }, [restoreUserSession]);

  useEffect(() => {
    if (user) {
      setSessionRestoreInProgress(false);
    }
  }, [user]);

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading,
        signIn,
        signUp,
        signOut,
      }}
    >
      {sessionRestoreInProgress ? <></> : children}
    </AuthContext.Provider>
  );
};
