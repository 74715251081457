import { ErrorAlert } from "./ErrorAlert";
import React, { FC } from "react";
import { Routes } from "../../routes";

export const BadCredentialsAlert: FC = () => (
  <ErrorAlert title={"Hmm, your credentials do not match."}>
    Please check your credentials and submit the form again or{" "}
    <a href={Routes.public.resetPassword} className="text-red-900">
      reset your password.
    </a>
  </ErrorAlert>
);
