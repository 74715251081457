import React, { FC } from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import {
  CreateAccount,
  Login,
  ResetPassword,
  Root as AuthenticatedRoot,
} from "./pages";
import { PrivateRoute } from "./components/Navigation";
import { DataProvider } from "./context";
import { NotificationsProvider } from "./context/NotificationsContext";

export const App: FC = () => {
  return (
    <Router>
      <Switch>
        <Route path={"/login"} component={Login} />
        <Route path={"/reset-password"} component={ResetPassword} />
        <Route path={"/start"} component={CreateAccount} />

        <PrivateRoute path="/">
          <NotificationsProvider>
            <DataProvider>
              <AuthenticatedRoot />
            </DataProvider>
          </NotificationsProvider>
        </PrivateRoute>
      </Switch>
    </Router>
  );
};
