import { FC, useState } from "react";
import { PlanPicker } from "../PlanPicker";
import { Button } from "../Base/Form";
import { PlanPickerProps } from "../PlanPicker/PlanPicker";
import { CreateProjectFormStepProps } from "../CreateProjectForm";

type Props = CreateProjectFormStepProps & PlanPickerProps;
export const ProjectPlanForm: FC<Props> = ({
  buttonText,
  onFormSubmitted,
  ...rest
}) => {
  const [currentPlan, setCurrentPlan] = useState<any>();

  const onSubmit = () => {
    onFormSubmitted({ planId: currentPlan.id });
  };
  return (
    <>
      <div className="my-5">
        <PlanPicker
          className="max-w-full"
          {...rest}
          onPlanChanged={setCurrentPlan}
        />
      </div>
      <div className="flex justify-end">
        <Button onClick={onSubmit} type="submit">
          {buttonText || "Next Step"}
        </Button>
      </div>
    </>
  );
};
