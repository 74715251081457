import { PlusIcon } from "@heroicons/react/outline";
import { Button } from "../../../components/Base/Form";

export const EmptyWebhooksView = () => {
  return (
    <div className="w-full h-[calc(100vh-200px)] flex max-w-[400px] mx-auto text-center pt-24 flex flex-col">
      <p className="text-gray-400 mb-3">You do not have any webhooks defined</p>
      <Button className="bg-green-500 mx-auto items-center hover:bg-green-600">
        <PlusIcon width={16} className="mr-2" />
        Create your first webhook
      </Button>
      <p className="text-gray-400 mt-3 text-xs">
        Webhooks are giving you the ability to execute actions inside or outside
        of the Architect System by executing a POST request to the defined URL.
      </p>
    </div>
  );
};
