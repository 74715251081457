import React, { FC, useRef, useState } from "react";
import { useForm } from "react-hook-form";

import { useAuth } from "../../hooks";

import { CreateAccountRequest } from "../../types";

import { Logo } from "../../assets/brand";

import { Subtitle } from "../Base/Typography";
import { Button, Input } from "../Base/Form";

import {
  createAccountDefaults,
  createAccountResolver,
  errorAlert,
} from "./constants";
import { AnimatePresence, motion } from "framer-motion";
import { Separator } from "../Base/Layout";
import { SocialLoginButton } from "../SocialLoginButton";
import { Routes } from "../../routes";
import { useHistory } from "react-router-dom";

export const CreateAccountForm: FC = () => {
  const history = useHistory();

  const { signUp, isLoading } = useAuth();
  const [authError, setAuthError] = useState<number>();
  const errorAlertRef = useRef<HTMLDivElement>(null);

  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm<CreateAccountRequest>({
    resolver: createAccountResolver,
    defaultValues: createAccountDefaults,
  });

  const attemptAccountCreation = (data: CreateAccountRequest) => {
    setAuthError(undefined);
    signUp(data).then((response) => {
      if (response.success) {
        history.replace("/create-project");
      } else {
        setAuthError(response.status);
      }
    });
  };

  const onSubmit = handleSubmit(attemptAccountCreation);

  return (
    <div className={`relative mx-auto w-full max-w-sm lg:w-96`}>
      <div>
        <Logo className="h-12 w-auto" />
        <Subtitle>Create your account</Subtitle>
        <p className="mt-2">
          Or <a href={Routes.public.login}>go back to Login</a>
        </p>
      </div>

      <div className="mt-8">
        <div>
          <div>
            <p className="text-sm font-medium text-gray-700">
              Create account with
            </p>

            <div className="mt-1 grid grid-cols-3 gap-3">
              <SocialLoginButton icon={"google"} />
              <SocialLoginButton icon={"github"} />
              <SocialLoginButton icon={"apple"} />
            </div>
          </div>

          <Separator>Or continue with</Separator>
        </div>

        <div className="mt-6">
          <form onSubmit={onSubmit} className="space-y-6">
            <Input
              control={control}
              label={"First name"}
              name={"firstName"}
              errors={errors}
            />
            <Input
              control={control}
              label={"Last name"}
              name={"lastName"}
              errors={errors}
            />
            <Input
              control={control}
              label={"Email address"}
              name={"email"}
              type={"email"}
              errors={errors}
            />
            <Input
              control={control}
              label={"Password"}
              name={"password"}
              type={"password"}
              errors={errors}
            />

            <Button
              className={"w-full relative"}
              disabled={isLoading}
              isLoading={isLoading}
              type={"submit"}
            >
              Get access
            </Button>
          </form>
        </div>
      </div>

      <AnimatePresence>
        {authError && (
          <motion.div
            ref={errorAlertRef}
            initial={{ opacity: 0, y: 50, scale: 0.8 }}
            animate={{ opacity: 1, y: 0, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5, transition: { duration: 0.2 } }}
          >
            {errorAlert(authError)}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};
