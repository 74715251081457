import { FC, useState } from "react";
import { useForm } from "react-hook-form";
import { EmailAuthRequest } from "../../../../types";
import {
  loginDefaults,
  loginResolver,
} from "../../../../components/LoginForm/constants";
import { PlanPicker } from "../../../../components/PlanPicker";
import { Button } from "../../../../components/Base/Form";
const plans = [
  {
    name: "Free",
    records: "10K Records",
    requests: "20K Requests",
    extras: "No Extra Capabilities",
  },
  {
    name: "Starter",
    records: "25K Records",
    requests: "50K Requests",
    extras: "Limited Extra Capabilities",
  },
  {
    name: "Rolling",
    records: "100K Records",
    requests: "200K Requests",
    extras: "All Extra Capabilities",
  },
];

export const Billing: FC = () => {
  const [selected, setSelected] = useState(plans[0]);

  const { handleSubmit, control } = useForm<EmailAuthRequest>({
    resolver: loginResolver,
    defaultValues: loginDefaults,
  });

  return (
    <>
      <form>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Your current plan
              </h3>
              <p className="mt-1 max-w-[600px] text-sm text-gray-500">
                Pick the plan that suits your needs the most. Essentialz offers
                different plans to fit you business needs. To learn more about
                Essentialz Pricing, click here.
              </p>
            </div>
            <div className="w-full py-4">
              <PlanPicker onPlanChanged={setSelected} />
            </div>
          </div>

          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6">
            <button
              type="submit"
              className="bg-indigo-600 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Save
            </button>
          </div>
        </div>
      </form>
      <form>
        <div className="shadow sm:rounded-md sm:overflow-hidden">
          <div className="bg-white py-6 px-4 space-y-6 sm:p-6">
            <div>
              <h3 className="text-lg leading-6 font-medium text-gray-900">
                Set your billing information
              </h3>
              <p className="mt-1 max-w-[600px] text-sm text-gray-500">
                Essentialz uses Stripe to process all subscriptions. Add you
                billing information to unlock premium features.
              </p>
            </div>
            <div className="w-full py-4"></div>
          </div>

          <div className="px-4 py-3 bg-gray-50 text-right sm:px-6 flex justify-end">
            <Button type="submit">Save</Button>
          </div>
        </div>
      </form>
    </>
  );
};
