import { Subtitle } from "../../components/Base/Typography";
import { useProjects } from "../../hooks";
import { Project } from "../../types";
import { useHistory } from "react-router-dom";
import { Routes } from "../../routes";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { ReactComponent as CreateProjectIcon } from "../../assets/icons/create-project.svg";

import { useMemo } from "react";
import { Page } from "../../templates";
import { FullPageWithCenteredContent } from "../../templates/Page/FullPageWithCenteredContent";

export const SwitchProject = () => {
  const history = useHistory();
  const { projects, setSelectedProject } = useProjects();

  const onProjectSelected = (project: Project) => {
    setSelectedProject(project);
    history.replace(Routes.private.project.home);
  };

  const hasProjects = useMemo(
    () => projects && projects?.length > 0,
    [projects]
  );
  const pageTitle = useMemo(
    () =>
      hasProjects ? "Select your project" : "You do not have any projects",
    [hasProjects]
  );
  return (
    <Page template={FullPageWithCenteredContent}>
      <Subtitle className="mb-6">{pageTitle}</Subtitle>
      {hasProjects ? (
        <>
          <ul role="list" className="grid grid-cols-1 gap-4">
            {projects?.map((project) => (
              <li
                key={project.name}
                className="col-span-1 bg-white rounded-lg shadow divide-y divide-gray-200"
              >
                <div className="w-full flex items-center justify-between p-6 space-x-6">
                  <div className="flex-1 truncate">
                    <div className="flex items-center space-x-3">
                      <h3 className="text-gray-900 text-sm font-medium truncate">
                        {project.name}
                      </h3>
                      <p className="flex-shrink-0 inline-block px-2 py-0.5 text-green-800 text-xs font-medium bg-green-100 rounded-full">
                        {project.appId}
                      </p>
                    </div>
                    <p className="mt-1 text-gray-500 text-sm truncate">
                      {`https://${project.projectDomain}`}
                    </p>
                  </div>
                </div>
                <div>
                  <div className="-mt-px flex divide-x divide-gray-200">
                    <div className="w-0 flex-1 flex">
                      <div
                        onClick={() => onProjectSelected(project)}
                        className="cursor-pointer relative -mr-px w-0 flex-1 inline-flex items-center justify-center py-4 text-sm text-gray-700 font-medium border border-transparent rounded-bl-lg hover:text-gray-500"
                      >
                        <span className="ml-3">Select</span>
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            ))}
          </ul>
          <div className="mt-4 flex align-middle items-center">
            <div
              onClick={() => history.goBack()}
              className="w-20 cursor-pointer items-center px-8 py-2 border border-gray-300 shadow-sm text-base font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              <ArrowLeftIcon width={20} />
            </div>
            <p className="ml-3 text-md">Back to dashboard</p>
          </div>
        </>
      ) : (
        <button
          type="button"
          onClick={() => history.push(Routes.private.user.createProject)}
          className="relative block w-full border-2 border-gray-300 border-dashed rounded-lg p-12 text-center hover:border-gray-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <CreateProjectIcon width={40} className={"mx-auto"} />
          <p className="mt-3 block text-sm font-medium text-gray-900">
            Create a new project
          </p>
        </button>
      )}
    </Page>
  );
};
