import { FC, HTMLAttributes, useEffect, useState } from "react";
import { RadioGroup } from "@headlessui/react";
import { CheckIcon } from "@heroicons/react/solid";

const plans = [
  {
    id: 1,
    name: "Free",
    records: "10K Records",
    requests: "20K Requests",
    extras: "No Extra Capabilities",
  },
  {
    id: 2,
    name: "Starter",
    records: "25K Records",
    requests: "50K Requests",
    extras: "Limited Extra Capabilities",
  },
  {
    id: 3,
    name: "Rolling",
    records: "100K Records",
    requests: "200K Requests",
    extras: "All Extra Capabilities",
  },
];

type Props = {
  onPlanChanged?: (plan: any) => void;
} & HTMLAttributes<HTMLDivElement>;

export type PlanPickerProps = Props;
export const PlanPicker: FC<Props> = ({
  onPlanChanged,
  className,
  ...rest
}) => {
  const [selected, setSelected] = useState(plans[0]);

  useEffect(() => {
    if (onPlanChanged) {
      onPlanChanged(selected);
    }
  }, [onPlanChanged, selected]);

  return (
    <div className={`w-full max-w-md ${className || ""}`} {...rest}>
      <RadioGroup value={selected} onChange={setSelected}>
        <RadioGroup.Label className="sr-only">Server size</RadioGroup.Label>
        <div className="space-y-2">
          {plans.map((plan) => (
            <RadioGroup.Option
              key={plan.name}
              value={plan}
              className={({ active, checked }) =>
                `${
                  active
                    ? "ring-2 ring-offset-2 ring-offset-blue-300 ring-white ring-opacity-60"
                    : ""
                }
                  ${checked ? "bg-blue text-white" : "bg-white"}
                    relative rounded-lg shadow-md px-5 py-4 cursor-pointer flex focus:outline-none`
              }
            >
              {({ active, checked }) => (
                <>
                  <div className="flex items-center justify-between w-full">
                    <div className="flex items-center">
                      <div className="text-sm">
                        <RadioGroup.Label
                          as="p"
                          className={`font-medium  ${
                            checked ? "text-white" : "text-gray-900"
                          }`}
                        >
                          {plan.name}
                        </RadioGroup.Label>
                        <RadioGroup.Description
                          as="span"
                          className={`inline ${
                            checked ? "text-blue-100" : "text-gray-500"
                          }`}
                        >
                          <span>
                            {plan.records}/{plan.requests}
                          </span>{" "}
                          <span aria-hidden="true">&middot;</span>{" "}
                          <span>{plan.extras}</span>
                        </RadioGroup.Description>
                      </div>
                    </div>
                    {checked && (
                      <div className="flex-shrink-0 text-white">
                        <CheckIcon className="w-6 h-6" />
                      </div>
                    )}
                  </div>
                </>
              )}
            </RadioGroup.Option>
          ))}
        </div>
      </RadioGroup>
    </div>
  );
};
