import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { App } from "./App";
import { ConfigProvider } from "./context/Config";
import { AuthProvider } from "./context";

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById("root")
);
