import { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowLeftIcon, TrashIcon } from "@heroicons/react/outline";

import { Button, Input, Select } from "../Base/Form";
import { CircleNotch, VerticalDots } from "../../assets/icons";
import { useFieldArray, useForm, useWatch } from "react-hook-form";
import { PolicyItem } from "../../types";
import { useArchitectApi, useNotifications, useProjects } from "../../hooks";

const colorMappings: Record<string, string> = {
  update: "bg-yellow-300",
  delete: "bg-red-400",
  create: "bg-green-500",
  show: "bg-blue-400",
};
const textColorMappings: Record<string, string> = {
  update: "text-black",
  delete: "text-white",
  create: "text-white",
  show: "text-white",
};

const availableActions = ["update", "delete", "create", "show"];
const operatorOptions = [
  {
    value: "current_user",
    label: "current user",
  },
  {
    value: "plain",
    label: "value",
  },
  {
    value: "entity",
    label: "entity",
  },
];
const operandOptions = [
  {
    value: "eq",
    label: "equals",
  },
  {
    value: "gt",
    label: "greater than",
  },
  {
    value: "lt",
    label: "less than",
  },
  {
    value: "contains",
    label: "contains",
  },
  {
    value: "arr_contains",
    label: "array contains",
  },
];

type Props = {
  open: boolean;
  close: () => void;
};
export const CreatePolicyModal: FC<Props> = ({ open = false, close }) => {
  const { selectedProject, selectedProjectSchema } = useProjects();
  const [isLoading, setIsLoading] = useState(false);
  const { renderNotification } = useNotifications();

  const { control, reset, handleSubmit } = useForm<PolicyItem>({
    defaultValues: {
      data: [],
    },
  });

  const { fields, remove, append } = useFieldArray({ control, name: "data" });

  useEffect(() => {
    if (selectedProjectSchema) {
      reset({
        action: availableActions[0],
        collectionName: selectedProjectSchema[0],
        data: [],
      });
    }
  }, [reset, selectedProjectSchema]);

  const selectedAction = useWatch({ name: "action", control });
  const collectionName = useWatch({ name: "collectionName", control });

  const architectApi = useArchitectApi(
    selectedProject,
    collectionName,
    "policy_items"
  );

  const onSubmit = handleSubmit(async (data) => {
    if (architectApi) {
      setIsLoading(true);
      const response = await architectApi.create(data);
      setIsLoading(false);
      if (response.success) {
        close();
        renderNotification({
          type: "success",
          message: "Created!",
          description: "Your policy has been created!",
        });
      }
    }
  });
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-10 inset-0 overflow-y-auto"
        onClose={() => {}}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-3xl sm:w-full sm:p-6">
              <div className=" w-full flex items-center">
                <button
                  type="button"
                  className="mt-3 w-[60px] inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:col-start-1 sm:text-sm"
                  onClick={close}
                >
                  <ArrowLeftIcon width={20} />
                </button>
                <p className="ml-3 text-xl">Create Policy</p>
              </div>

              <form onSubmit={onSubmit}>
                <div className="min-h-[500px] mt-8">
                  <div className="flex items-center">
                    <span className="text-gray-500 mr-4 text-sm w-32">
                      Create policy for
                    </span>
                    <div className="w-72">
                      <Select
                        control={control}
                        name="collectionName"
                        values={selectedProjectSchema || []}
                      />
                    </div>
                  </div>
                  <div className="flex mt-4 items-center">
                    <span className="text-gray-500 mr-4 text-sm w-32">
                      Allow
                    </span>
                    <div className="w-72">
                      <Select
                        control={control}
                        name="action"
                        values={availableActions}
                      />
                    </div>
                  </div>
                  <div className="mb-4 mt-4 text-gray-500 mr-4 text-sm leading-7 border rounded-lg">
                    <div className="p-5">
                      <span>Allow requests matching the conditions to</span>{" "}
                      <span
                        className={`${textColorMappings[selectedAction]} ${colorMappings[selectedAction]} pt-1 pb-1.5 px-2 rounded`}
                      >
                        {selectedAction}
                      </span>{" "}
                      a record {selectedAction === "create" ? "in" : "from"}{" "}
                      table{" "}
                      <span className="rounded text-white bg-indigo-500 pt-1 pb-1.5 px-2">
                        {collectionName}
                      </span>
                    </div>
                    {fields.map((item, index) => (
                      <div key={item.id} className="relative">
                        <div className="p-5  bg-gray-100">
                          <div className="flex items-center w-full">
                            <div className="text-gray-400 w-8">if</div>
                            <div className="flex">
                              <div className="w-[140px]">
                                <Select
                                  control={control}
                                  values={operatorOptions.map((o) => o.value)}
                                  labels={operatorOptions.map((o) => o.label)}
                                  name={`data[${index}].left.type`}
                                />
                              </div>
                              <div className="ml-2">
                                <Input
                                  control={control}
                                  name={`data[${index}].left.value`}
                                />
                              </div>
                              <div className="mx-2 w-[160px]">
                                <Select
                                  control={control}
                                  name={`data[${index}].operator`}
                                  values={operandOptions.map((o) => o.value)}
                                  labels={operandOptions.map((o) => o.label)}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="ml-8 flex items-center w-full">
                            <div className="mt-2 flex">
                              <div className="w-[140px]">
                                <Select
                                  control={control}
                                  name={`data[${index}].right.type`}
                                  values={operatorOptions.map((o) => o.value)}
                                  labels={operatorOptions.map((o) => o.label)}
                                />
                              </div>
                              <div className="ml-2">
                                <Input
                                  control={control}
                                  name={`data[${index}].right.value`}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            onClick={() => remove(index)}
                            className="absolute cursor-pointer hover:shadow-sm hover:scale-105 transition-all top-0 bottom-0 m-auto right-5 w-10 h-10 border border-red-200 rounded-lg flex justify-center items-center"
                          >
                            <TrashIcon width={20} className="text-red-400" />
                          </div>
                        </div>
                        {index < fields.length - 1 && (
                          <div className="bg-gray-100 flex items-center justify-center flex-col">
                            <CircleNotch
                              width={14}
                              className={"text-green-500"}
                            />
                            <VerticalDots
                              height={22}
                              className={"text-green-500"}
                            />
                            <span className="text-gray-400">And</span>
                          </div>
                        )}
                      </div>
                    ))}

                    <div className="bg-gray-100 flex items-center justify-center flex-col py-3">
                      <CircleNotch width={14} className={"text-green-500"} />
                      <VerticalDots height={22} className={"text-green-500"} />
                      <span
                        onClick={() => append({})}
                        className=" text-xs text-gray-400 px-3 py-1 border border-green-500 rounded-full mb-2 cursor-pointer"
                      >
                        Add new condition
                      </span>
                    </div>
                  </div>
                </div>
                <div className="flex justify-end">
                  <Button
                    isLoading={isLoading}
                    disabled={isLoading}
                    type="submit"
                  >
                    Create policy
                  </Button>
                </div>
              </form>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};
